enum CountryCode {
    BR = "+55"
}

export default class PhoneUtils {
    static addBRLCountryCode(phoneNumber: string) {
        // TODO(lucas.citolin): check if any country code is already present
        if(phoneNumber.startsWith(CountryCode.BR)) {
            return phoneNumber
        }
        return CountryCode.BR + phoneNumber
    }  
}