export enum ImageLabel {
  PORTRAIT = 'portrait',

  CNH_FRONT = 'cnh_frente',
  CNH_BACK = 'cnh_verso',

  RG_FRONT = 'rg_frente',
  RG_BACK = 'rg_verso',

  MILITARY_RG_FRONT = 'identidade_militar_frente',
  MILITARY_RG_BACK = 'identidade_militar_verso',

  PASSPORT_FRONT = 'passaporte',
  PASSPORT_BACK = 'passaporte_verso',

  CTPS_FRONT = 'carteira_de_trabalho',
  CTPS_BACK = 'carteira_de_trabalho_verso',

  FOREING_ID_FRONT = 'rg_estrangeiro',
  FOREING_ID_BACK = 'rg_estrangeiro_verso',

  NEW_CNH_FRONT = 'nova_cnh_frente',
  NEW_CNH_BACK = 'nova_cnh_verso',
}

export enum AllowedDocuments {
  RG = 'RG',
  MILITARY_RG = 'MILITARY_RG',
  CNH = 'CNH',
  PASSPORT = 'PASSPORT',
  CTPS = 'CTPS',
  FOREIGN_ID = 'FOREIGN_ID',
  NEW_CNH = 'NEW_CNH',
}

export enum DocumentViewAngle {
  ALL = 'ALL',
  FRONT = 'FRONT',
  BACK = 'BACK',
}

export interface DocumentPhotoDescription {
  document: AllowedDocuments;
  documentViewAngle: DocumentViewAngle;
  documentViewAngleType: ImageLabel;
}

class ApplicationDocumentsUtils {
  static DOCUMENT_LABEL_TO_UI_TEXT_MAP: { [key: string]: string } = {
    [AllowedDocuments.RG]: 'RG',
    [AllowedDocuments.MILITARY_RG]: 'Identidade Militar',
    [AllowedDocuments.CNH]: 'CNH',
    [AllowedDocuments.PASSPORT]: 'Passaporte',
    [AllowedDocuments.CTPS]: 'CTPS',
    [AllowedDocuments.FOREIGN_ID]: 'RG Estrangeiro',
    [AllowedDocuments.NEW_CNH]: 'Nova CNH',
  };

  static ALLOWED_DOCUMENTS = {
    [AllowedDocuments.RG]: {
      uiLabel: this.DOCUMENT_LABEL_TO_UI_TEXT_MAP[AllowedDocuments.RG],
      front: ImageLabel.RG_FRONT,
      back: ImageLabel.RG_BACK,
    },
    [AllowedDocuments.MILITARY_RG]: {
      uiLabel: this.DOCUMENT_LABEL_TO_UI_TEXT_MAP[AllowedDocuments.MILITARY_RG],
      front: ImageLabel.MILITARY_RG_FRONT,
      back: ImageLabel.MILITARY_RG_BACK,
    },
    [AllowedDocuments.CNH]: {
      uiLabel: this.DOCUMENT_LABEL_TO_UI_TEXT_MAP[AllowedDocuments.CNH],
      front: ImageLabel.CNH_FRONT,
      back: ImageLabel.CNH_BACK,
    },
    [AllowedDocuments.PASSPORT]: {
      uiLabel: this.DOCUMENT_LABEL_TO_UI_TEXT_MAP[AllowedDocuments.PASSPORT],
      front: ImageLabel.PASSPORT_FRONT,
      back: ImageLabel.PASSPORT_BACK,
    },
    [AllowedDocuments.CTPS]: {
      uiLabel: this.DOCUMENT_LABEL_TO_UI_TEXT_MAP[AllowedDocuments.CTPS],
      front: ImageLabel.CTPS_FRONT,
      back: ImageLabel.CTPS_BACK,
    },
    [AllowedDocuments.FOREIGN_ID]: {
      uiLabel: this.DOCUMENT_LABEL_TO_UI_TEXT_MAP[AllowedDocuments.FOREIGN_ID],
      front: ImageLabel.FOREING_ID_FRONT,
      back: ImageLabel.FOREING_ID_BACK,
    },
    [AllowedDocuments.NEW_CNH]: {
      uiLabel: this.DOCUMENT_LABEL_TO_UI_TEXT_MAP[AllowedDocuments.NEW_CNH],
      front: ImageLabel.NEW_CNH_FRONT,
      back: ImageLabel.NEW_CNH_BACK,
    },
  };
}

export default ApplicationDocumentsUtils;
