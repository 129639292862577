export default class Base64Utils {
  static DATA_URI_SCHEME = 'data:';

  static removeDataUriScheme(base64String: string) {
    const commaIndex = base64String.indexOf(',');
    if (base64String.startsWith(this.DATA_URI_SCHEME) && commaIndex !== -1) {
      return base64String.slice(commaIndex + 1);
    } else {
      return base64String;
    }
  }
  static addPrefixImageData(base64: string): string {
    return base64.startsWith('data:')
      ? base64
      : `data:image/jpeg;base64,${base64}`;
  }
}
