import React from 'react';
import styled from 'styled-components';
import { ReactComponent as ThumbsUpSVGIcon } from '../assets/icons/thumbs_up.svg';
import UmeConfigs from '../utils/UmeConfigs';

const WaitingManaualApprovalPage = () => {
  return (
    <Container>
      <ThumbsUpSVGIcon fontSize={'large'} style={{ color: '#6CED7F' }} />
      <StrongText> Obrigado, agora é com a gente </StrongText>
      <Explanation>
        Seus dados estão em análise e assim que tivermos uma resposta você será avisado no número cadastrado. Caso tenha dúvidas, acesse nossa{' '}
        <UnderlinedLink href={UmeConfigs.HELP_CENTER_URL} target='_blank'>
          Central de Ajuda.
        </UnderlinedLink>
      </Explanation>
    </Container>
  );
};

export default WaitingManaualApprovalPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10%;

  height: 75%;
`;

const UnderlinedLink = styled.a`
  color: #454b46;
`;

const StrongText = styled.h1`
  margin-top: 3vh;

  text-align: center;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;

  color: #454b46;
`;

const Explanation = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;
  letter-spacing: 0.15px;

  /* Color/Dark/300 */

  color: #7a7e7a;
`;
