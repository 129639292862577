import {
  UnicoCheckBuilder,
  UnicoConfig,
  SelfieCameraTypes,
  UnicoThemeBuilder,
  DocumentCameraType,
  DocumentCameraTypes,
  CameraOpener,
} from 'unico-webframe';
import AppConfig from '../../config/config';
import { ImageLabel } from '../../utils/ApplicationDocumentsUtils';

class UnicoSDKService {
  camera: Promise<CameraOpener> | undefined;
  constructor(photoType: ImageLabel) {
    const config = AppConfig.getConfig().shouldUseLiveness
      ? AppConfig.getConfig().unicoLivenessSDKConfig
      : AppConfig.getConfig().unicoSDKConfig;
    const unicoConfig = new UnicoConfig()
      .setProjectNumber(config.projectNumber)
      .setProjectId(config.projectId)
      .setMobileSdkAppId(config.mobileSDKAppId)
      .setHostname(config.hostname)
      .setHostInfo(config.hostInfo)
      .setHostKey(config.hostKey);

    const unicoCameraBuilder = new UnicoCheckBuilder();

    const unicoTheme = new UnicoThemeBuilder()
      .setColorSilhouetteSuccess('#11b048')
      .setColorSilhouetteError('#D50000')
      .setColorSilhouetteNeutral('#fcfcfc')
      .setBackgroundColor('#dff1f5')
      .setColorText('#11b048')
      .setBackgroundColorComponents('#11b048')
      .setColorTextComponents('#dff1f5') //label color in frame capture
      .setBackgroundColorButtons('#11b048')
      .setColorTextButtons('#dff1f5')
      .setBackgroundColorBoxMessage('#fff')
      .setColorTextBoxMessage('#000')
      .setHtmlPopupLoading(
        `<div style="position: absolute; top: 45%; right: 50%; transform:
  translate(50%, -50%); z-index: 10; text-align: center;">Carregando...</div>`
      )
      .build();

    unicoCameraBuilder
      .setModelsPath(config.modelsPath)
      .setResourceDirectory('/resources');
    unicoCameraBuilder.setTheme(unicoTheme);
    const unicoCamera = unicoCameraBuilder.build();

    this.camera =
      photoType === ImageLabel.PORTRAIT
        ? unicoCamera.prepareSelfieCamera(unicoConfig, SelfieCameraTypes.SMART)
        : unicoCamera.prepareDocumentCamera(
            unicoConfig,
            this.mapImageTypeToSDKType(photoType)
          );
  }

  private mapImageTypeToSDKType = (
    imageType: ImageLabel
  ): DocumentCameraType => {
    switch (imageType) {
      case ImageLabel.CNH_FRONT:
        return DocumentCameraTypes.CNH_FRENTE;
      case ImageLabel.CNH_BACK:
        return DocumentCameraTypes.CNH_VERSO;
      case ImageLabel.RG_FRONT:
        return DocumentCameraTypes.RG_FRENTE;
      case ImageLabel.RG_BACK:
        return DocumentCameraTypes.RG_VERSO;
      case ImageLabel.MILITARY_RG_FRONT:
      case ImageLabel.MILITARY_RG_BACK:
      case ImageLabel.PASSPORT_FRONT:
      case ImageLabel.PASSPORT_BACK:
      case ImageLabel.CTPS_FRONT:
      case ImageLabel.CTPS_BACK:
      case ImageLabel.FOREING_ID_FRONT:
      case ImageLabel.FOREING_ID_BACK:
      case ImageLabel.NEW_CNH_FRONT:
      case ImageLabel.NEW_CNH_BACK:
        return DocumentCameraTypes.OTHERS(` `);
      default:
        return DocumentCameraTypes.OTHERS(` `);
    }
  };
}

export default UnicoSDKService;
