import { AxiosInstance } from 'axios';
import { getAxiosInstance } from '../utils';
import { BffServiceError } from './BffServiceError';

export class BffService {
  private axiosInstance: AxiosInstance;
  constructor(baseURL: string) {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token: null,
    });
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    });
  }

  async startApplication(
    cpf: string,
    referralCode?: string,
    imageCaptureMode?: string
  ): Promise<StartApplicationResponseDTO | BffServiceError> {
    const response = await this.axiosInstance.post<
      StartApplicationResponseDTO | BffServiceError
    >('/applications', {
      cpf,
      referralCode: referralCode ? referralCode : undefined,
      imageCaptureMode: imageCaptureMode ? imageCaptureMode : undefined,
    });

    if (response instanceof BffServiceError) {
      return response;
    }

    return response.data;
  }

  async updateApplicationData(
    dataToUpdate: UpdateApplicationDataDTO
  ): Promise<void | BffServiceError> {
    const response = await this.axiosInstance.patch<void | BffServiceError>(
      `/applications`,
      dataToUpdate
    );

    if (response instanceof BffServiceError) {
      return response;
    }

    return;
  }

  async updateApplicationAddress(
    address: UpdateAddressDTO
  ): Promise<void | BffServiceError> {
    const response = await this.axiosInstance.put<void | BffServiceError>(
      `/applications/address`,
      address
    );

    if (response instanceof BffServiceError) {
      return response;
    }

    return;
  }

  async sendPhoneVerificationCode(
    phoneNumber: string
  ): Promise<void | BffServiceError> {
    const response = await this.axiosInstance.post<void | BffServiceError>(
      `/applications/phones/${phoneNumber}/verification-code`
    );

    if (response instanceof BffServiceError) {
      return response;
    }

    return;
  }

  async verifyPhoneVerificationCode(
    phoneNumber: string,
    verificationCode: string
  ): Promise<void | BffServiceError> {
    const response = await this.axiosInstance.patch<void | BffServiceError>(
      `/applications/phones/${phoneNumber}/verify`,
      { verificationCode }
    );

    if (response instanceof BffServiceError) {
      return response;
    }

    return;
  }

  async submitBiometry(
    imageBase64: string,
    photoVersion: string,
    logObject?: any
  ): Promise<SubmitBiometryResponseDTO | BffServiceError> {
    const response = await this.axiosInstance.post<
      SubmitBiometryResponseDTO | BffServiceError
    >(`/applications/biometry`, { imageBase64, photoVersion, logObject });

    if (response instanceof BffServiceError) {
      return response;
    }

    return response.data;
  }

  async submitDocument(
    documentType: string,
    imageBase64: string,
    photoVersion: string,
    logObject?: any
  ): Promise<SubmitDocumentResponseDTO | BffServiceError> {
    const response = await this.axiosInstance.post<
      SubmitDocumentResponseDTO | BffServiceError
    >(`/applications/document/${documentType}`, {
      imageBase64,
      photoVersion,
      logObject,
    });

    if (response instanceof BffServiceError) {
      return response;
    }

    return response.data;
  }

  async submitApplication(
    referralCode?: string
  ): Promise<void | BffServiceError> {
    const response = await this.axiosInstance.post<void | BffServiceError>(
      `/applications/evaluate`,
      { referralCode: referralCode ? referralCode : undefined }
    );

    if (response instanceof BffServiceError) {
      return response;
    }

    return response.data;
  }

  async getApplicationStatus(): Promise<
    GetApplicationStatusDTO | BffServiceError
  > {
    const response = await this.axiosInstance.get<
      GetApplicationStatusDTO | BffServiceError
    >(`/applications/status`);

    if (response instanceof BffServiceError) {
      return response;
    }

    return response.data;
  }
}

interface UpdateApplicationDataDTO {
  name?: string;
  birthDate?: string;
  professionId?: string;
  email?: string;
  address?: {
    zipCode: string;
    addressComplement: string;
    addressNumber: string;
    zipCodeInfo?: {
      cep?: string;
      logradouro?: string;
      complemento?: string;
      bairro?: string;
      localidade?: string;
      uf?: string;
      ibge?: string;
      gia?: string;
      ddd?: string;
    };
  };
  zipCode?: string;
}
interface StartApplicationResponseDTO {
  accessToken: string;
}

interface SubmitBiometryResponseDTO {
  id: string;
  status: string;
}

interface SubmitDocumentResponseDTO {
  id: string;
  status: string;
}

interface UpdateAddressDTO {
  zipCode: string;
  streetName: string;
  streetNumber: string;
  complement?: string;
  district: string;
  city: string;
  federativeUnit: string;
  ibge?: number;
}

export enum ApplicationStatus {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  FAILURE = 'FAILURE',
  UNDER_ANALYSIS = 'UNDER_ANALYSIS',
  WAITING_MANUAL_APPROVAL = 'WAITING_MANUAL_APPROVAL',
}

export interface GetApplicationStatusDTO {
  status: ApplicationStatus;
}
