import { InputAdornment, makeStyles, TextField, TextFieldProps, createStyles } from '@material-ui/core'
import DonutLargeIcon from '@material-ui/icons/DonutLarge'
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete'
import React, { ChangeEvent, useState } from 'react'
import styled from 'styled-components'
import { keyframes } from 'styled-components'

const ENTER_KEYCODE = 13

const useStyles = makeStyles(theme =>
  createStyles({
    default: {
      '& label.Mui-focused': {
        fontWeight: 'bold',
      },
    },
    onError: {
      '& label.Mui-focused': {
        color: theme.palette.error.main,
        fontWeight: 'bold',
      },
      '& label': {
        color: theme.palette.error.main,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: `${theme.palette.error.main} !important`,
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: `${theme.palette.error.main} !important`,
      },
    },
    onSuccess: {
      '& label.Mui-focused': {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
      },
      '& label': {
        color: theme.palette.primary.main,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: `${theme.palette.primary.main} !important`,
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: `${theme.palette.primary.main} !important`,
      },
    },
  })
)

interface IApplicationInputProps extends React.InputHTMLAttributes<TextFieldProps> {
  Icon?: React.ElementType
  value?: string
  label?: string
  placeholder?: string
  isLoading?: boolean
  isValidated?: boolean
  isDisabled?: boolean
  onChange?: (value: ChangeEvent<TextFieldProps>) => void
  onEnterPressed?: () => void
  style?: React.CSSProperties
  Mask?: React.ElementType
  params?: AutocompleteRenderInputParams
  adornmentStyle?: React.CSSProperties
  required?: boolean
  error?: boolean
  errorText?: string
  inputRef?: React.RefObject<HTMLInputElement>
}

const shakeAnimation = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`

export const ApplicationInput = (props: IApplicationInputProps) => {
  const classes = useStyles()
  const [isAnimationRunning, setAnimationRunning] = useState<boolean>(false)

  const {
    Icon,
    Mask,
    value,
    label,
    placeholder,
    isLoading,
    isValidated,
    isDisabled,
    params,
    error,
    errorText,
    adornmentStyle,
    required,
    onChange,
    onEnterPressed,
    ...rest
  } = props

  const handleChange = (event: ChangeEvent<TextFieldProps>) => {
    if (onChange) {
      onChange(event)
    }
  }

  const handleOnKeyDown = (event: React.KeyboardEvent<TextFieldProps>) => {
    if (event.keyCode === ENTER_KEYCODE && onEnterPressed) {
      if (error) {
        setAnimationRunning(true)
        // TODO - Is this anti-pattern?
        setTimeout(() => setAnimationRunning(false), 500)
      }

      onEnterPressed()
    }
  }

  const handleAdornmentClick = () => {
    if (onEnterPressed && !isDisabled) {
      onEnterPressed()
    }
  }

  const currentClass = error ? classes.onError : isValidated ? classes.onSuccess : classes.default

  return (
    <>
      <StyledTextField
        style={{
          '& label.Mui-focused': {
            fontWeight: 'bold',
          },
        }}
        {...params}
        {...rest}
        isAnimationRunning={isAnimationRunning ? 1 : 0}
        disabled={isDisabled}
        required={required}
        className={currentClass}
        onKeyDown={handleOnKeyDown}
        onChange={handleChange}
        value={value}
        label={label}
        InputProps={{
          ...(params && params.InputProps),
          style: adornmentStyle ? adornmentStyle : undefined,
          inputComponent: Mask ? Mask : undefined,
          endAdornment: (
            <InputAdornment style={{ color: error ? 'red' : isValidated ? '#11B048' : '#9A9A9A' }} position="end">
              {isLoading ? <LoadingIcon /> : Icon ? <Icon onClick={handleAdornmentClick} /> : <></>}
            </InputAdornment>
          ),
        }}
        placeholder={placeholder}
      />
      {error && errorText ? <LabelError>{errorText}</LabelError> : <></>}
    </>
  )
}

// TODO - How to not loose the NumberFormat type
const StyledTextField = styled(TextField)<any>`
  animation: 1s ${shakeAnimation} ${(props: any) => (props && props.isAnimationRunning ? 'infinite' : 'block')};
`

const LabelError = styled.p`
  color: #f44336;
  font-family: Roboto;
  margin: 0;
`

const LoadingIcon = styled(DonutLargeIcon)`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  animation: spin 1s linear infinite;
`
