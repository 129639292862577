import { toast, Slide} from 'react-toastify';

export class AppToaster {
  static showErrorToast = (stringMessage: string, autoCloseSeconds?: number) => {
    toast.error(stringMessage, {
      position: "bottom-center",
      autoClose: autoCloseSeconds ?? 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      pauseOnFocusLoss: false,
      transition: Slide
    });
  }

  static showSuccessToast = (stringMessage: string) => {
    toast.success(stringMessage, {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      pauseOnFocusLoss: false,
      transition: Slide
    });
  }

}
