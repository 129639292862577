import React, { useState } from 'react';
import styled from 'styled-components';
import { bffService } from '../services';
import { BffServiceError } from '../services/bff/BffServiceError';
import BiometryErrorsMapper from '../utils/BiometryErrorsMapper';
import TakePhotoOrganism from '../components/TakePhotoOrganism';
import MobileHeader from '../layout/MobileHeader';
import GoBack from '../components/GoBack';
import ApplicationDocumentsUtils, {
  DocumentPhotoDescription,
  DocumentViewAngle,
} from '../utils/ApplicationDocumentsUtils';
import Base64Utils from '../utils/Base64Utils';
import UnicoSDKService from '../services/unico/UnicoSdkService';

interface Props {
  documentPhotoDescription?: DocumentPhotoDescription;

  onGoBack: () => void;

  onSuccessfulDocumentPhoto: (
    documentPhotoDescription: DocumentPhotoDescription,
    imageBase64: string
  ) => void;
}

const DocumentTitleUIMapper = (
  documentPhotoDescription?: DocumentPhotoDescription
) => {
  const prefix =
    documentPhotoDescription?.documentViewAngle === DocumentViewAngle.FRONT
      ? `da frente:`
      : `do verso:`;
  return `Foto ${prefix} ${
    ApplicationDocumentsUtils.DOCUMENT_LABEL_TO_UI_TEXT_MAP[
      documentPhotoDescription?.document as string
    ]
  }`;
};

const DocumentCameraPage = ({
  documentPhotoDescription,
  onGoBack,
  onSuccessfulDocumentPhoto,
}: Props) => {
  const [imageErrorExplanation, setImageErrorExplanation] =
    useState<string>('');
  const [numberOfAttempts, setNumberOfAttempts] = useState<number>(0);

  const [takenImage, setTakenImage] = useState<string>();

  const [isSubmittingBiometry, setIsSubmittingBiometry] =
    useState<boolean>(false);
  const [isBiometrySubmitted, setIsBiometrySubmitted] =
    useState<boolean>(false);

  const handleDocumentPhoto = async (
    imageBase64: string,
    photoVersion?: any,
    logObject?: any
  ) => {
    setImageErrorExplanation('');
    setTakenImage(imageBase64);
    if (!documentPhotoDescription) return;

    setIsSubmittingBiometry(true);

    const submittedBiometry = await bffService.submitDocument(
      documentPhotoDescription.documentViewAngleType,
      Base64Utils.removeDataUriScheme(imageBase64),
      photoVersion,
      logObject
    );
    if (submittedBiometry instanceof BffServiceError) {
      setIsSubmittingBiometry(false);
      setNumberOfAttempts(numberOfAttempts + 1);
      setImageErrorExplanation(
        BiometryErrorsMapper.mapErrorToUIString(
          submittedBiometry.message as any
        )
      );
      return;
    }

    setIsBiometrySubmitted(true);
    setIsSubmittingBiometry(false);
    onSuccessfulDocumentPhoto(documentPhotoDescription, imageBase64);
  };
  const cameraInstance = new UnicoSDKService(
    documentPhotoDescription!.documentViewAngleType
  );
  return (
    <Container>
      <MobileHeader />
      <PageContainer>
        <GoBack onClick={onGoBack} />

        <DocumentTitle>
          {DocumentTitleUIMapper(documentPhotoDescription)}
        </DocumentTitle>

        <TakePhotoOrganism
          imageErrorExplanation={imageErrorExplanation}
          isHandlingPhoto={isSubmittingBiometry}
          isPhotoValid={isBiometrySubmitted}
          useFrontalCamera={false}
          onPhoto={handleDocumentPhoto}
          cameraSDK={cameraInstance}
          takenImage={takenImage}
        />
      </PageContainer>
    </Container>
  );
};

export default DocumentCameraPage;

const Container = styled.div`
  height: 100%;

  text-align: justify;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const PageContainer = styled.div`
  height: 100%;

  text-align: justify;

  margin: 8%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  // padding: 0% 5% 5% 5%;
`;

const DocumentTitle = styled.span`
  text-align: center;
`;
