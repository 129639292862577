import axios, { HttpStatusCode } from 'axios'
import { BffServiceError } from './bff/BffServiceError'

type UmeAxiosInstanceProps = {
  baseURL: string
  token?: string | null
}

export const getAxiosInstance = (props: UmeAxiosInstanceProps) => {
  const { baseURL, token } = props

  const axiosInstance = axios.create({
    baseURL,
    timeout: 300000, // 5 minutes
    maxRedirects: 5,
  })

  // injects Authorization header
  axiosInstance.interceptors.request.use(async config => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  })

  axiosInstance.interceptors.response.use(undefined, async error => {
    if(error.response?.data?.statusCode === HttpStatusCode.Forbidden ||
       error.response?.data?.statusCode === HttpStatusCode.Unauthorized) {
        window.location.reload()
    }
    if(error) {
      return new BffServiceError(error.response?.data?.error)
    }
  })


  return axiosInstance
}

