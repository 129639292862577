import { FirebaseApp, initializeApp } from 'firebase/app';
import { Analytics, getAnalytics, logEvent } from 'firebase/analytics';
import { FirebasePerformance, getPerformance } from 'firebase/performance';
import AppConfig from '../../config/config';
import BrowserInfoUtils from '../../utils/BrowserInfoUtils';

enum LogEvent {
  SCREEN_VIEW = 'screen_view',
  APP_EXCEPTION = 'app_exception',
}
let app: FirebaseApp;
export default class FirebaseService {
  static init(): void {
    const config = AppConfig.getConfig();
    app = initializeApp(config.firebaseConfig);
    this.performance();
  }

  static logScreenView(screen: string): void {
    logEvent(this.analytics(), LogEvent.SCREEN_VIEW, {
      firebase_screen: screen,
      firebase_screen_class: screen,
      browser: BrowserInfoUtils.browserInfos.name,
    });
  }

  static logAppException(errorDescription: string): void {
    logEvent(this.analytics(), LogEvent.APP_EXCEPTION, {
      description: errorDescription,
      timestamp: Date.now(),
      fatal: true,
    });
  }

  private static analytics(): Analytics {
    return getAnalytics(app);
  }

  private static performance(): FirebasePerformance {
    return getPerformance(app);
  }
}
