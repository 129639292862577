import { currentEnv, Environments } from "../config/config";
import { BffService } from "./bff/BffService";

const baseUrlByEnv: {[key: string]: string} = {
    [Environments.PRD]: 'https://api.ume.com.br/acquisitions/application-online-bff/v1',
    [Environments.STG]: 'https://stage-api.ume.com.br/acquisitions/application-online-bff/v1',
    [Environments.DEV]: 'https://dev-api.ume.com.br/acquisitions/application-online-bff/v1'
}

const baseURL = baseUrlByEnv[currentEnv]

console.log({ currentEnv, baseURL, reactAppNodeEnv: process.env.REACT_APP_NODE_ENV })

const bffService = new BffService(baseURL)

const startBffService = (accessToken: string) => {
    bffService._init(baseURL, accessToken)
}

export { bffService, startBffService }