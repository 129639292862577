import React, { useEffect, useRef } from "react";
import Webcam from "react-webcam";
import styled from "styled-components";
import BrowserInfoUtils from "../utils/BrowserInfoUtils";

interface Props {
  onPhoto: (imageBase64: string) => void;
  useFrontalCamera: boolean;
  children: string | JSX.Element | JSX.Element[];
}
const IMAGE_WIDTH = 1440;
const IMAGE_HEIGHT = 1080;

const OpenWebcamButton = ({ onPhoto, useFrontalCamera, children }: Props) => {
  const webcamRef = useRef<any>(null);

  const handleTakenPhoto = () => {
    const imageBase64 = webcamRef.current.getScreenshot();
    if (imageBase64) onPhoto(imageBase64);
  };

  const shouldUseFrontalCam = () => {
    const isMobile = BrowserInfoUtils.isMobile();
    return isMobile && useFrontalCamera;
  };

  return (
    <>
      <Webcam
        width="100%"
        height="100%"
        audio={false}
        screenshotFormat="image/jpeg"
        screenshotQuality={1}
        ref={webcamRef}
        videoConstraints={{
          width: IMAGE_WIDTH,
          height: IMAGE_HEIGHT,
          facingMode: shouldUseFrontalCam() ? "user" : { ideal: "environment" },
        }}
      />
      <CustomButton onClick={handleTakenPhoto}>{children}</CustomButton>
    </>
  );
};

export default OpenWebcamButton;

const CustomButton = styled.span`
  display: inline-block;
  background-color: #11b048 !important;
  color: white;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 0px 10px 0px;
  margin-top: 10px;
`;
