import React, { useState } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { CPFState, DocumentUtils } from '../utils/DocumentUtils';
import UmeLogo from '../assets/ume_logo.png';
import UmeWaves from '../assets/ume_waves.png';
import styled from 'styled-components';
import Colors from '../utils/Colors';
import NumberField from '../components/NumberField';
import { AppToaster } from '../components/toaster/AppToaster';
import applicationReducer from '../redux/reducers/application.reducer';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { bffService, startBffService } from '../services';
import {
  BffServiceError,
  BffServiceErrors,
} from '../services/bff/BffServiceError';
import AppConfig from '../config/config';
import { imageCaptureOptions } from '../common/enums';
import ConditionsAndTerms from '../components/ConditionsAndTerms';
import UmeConfigs from '../utils/UmeConfigs';

let isButtonPressed = false;

const CpfPage = () => {
  const dispatch = useAppDispatch();
  const [formCPF, setFormCPF] = useState<string>('');
  const [cpfState, setCPFState] = useState<CPFState>(CPFState.EMPTY);
  const [isChecked, setIsChecked] = useState(false)
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const referralCode = useAppSelector(
    (state) => state.application.referralCode
  );

  const handleCpfChange = (values: NumberFormatValues) => {
    setFormCPF(values.value);

    const cpfState = DocumentUtils.validateCPF(values.value);
    setCPFState(cpfState);
  };

  const handleCheckBox = (): void => {
    setIsChecked(!isChecked)
}

  const handleEnter = async () => {
    if (isButtonPressed) return;
    isButtonPressed = true;

    if (cpfState !== CPFState.VALID) {
      return AppToaster.showErrorToast(`Preencha um CPF válido`);
    }

    dispatch(
      applicationReducer.actions.onFormChange({ type: 'cpf', value: formCPF })
    );

    // START
    setIsLoading(true);
    const startApplicationResponse = await bffService.startApplication(
      formCPF,
      referralCode,
      AppConfig.getConfig().shouldUseLiveness
        ? imageCaptureOptions.SDK_LIVENESS
        : undefined
    );

    if (startApplicationResponse instanceof BffServiceError) {
      setIsLoading(false);
      if (
        startApplicationResponse.message ===
        BffServiceErrors.APPLICATION_ALREADY_STARTED
      ) {
        dispatch(applicationReducer.actions.onApplicationAlreadyExists());
        return;
      }
      if (
        startApplicationResponse.message ===
        BffServiceErrors.APPLICATION_WAITING_MANUAL_APPROVAL
      ) {
        dispatch(
          applicationReducer.actions.onApplicationWaitingManualApproval()
        );
        return;
      }

      // TODO(lucas.citolin): handle errors
      setTimeout(() => (isButtonPressed = false), 1000);

      return AppToaster.showErrorToast('CPF inválido');
    }

    startBffService(startApplicationResponse.accessToken);
    dispatch(applicationReducer.actions.nextPage());
    setIsLoading(false);
  };

  return (
    <Container>
      <img src={UmeLogo} alt='UME Logo' height={100} width={100} />

      <Title> Cadastre-se agora! </Title>
      <SubTitle>Para iniciar o cadastro, digite o seu CPF</SubTitle>
      <DocumentContainer>
        <NumberField
          autoFocus
          label='CPF'
          value={formCPF}
          type='tel'
          format='###.###.###-##'
          mask='_'
          placeholder='000.000.000-00'
          onValueChange={handleCpfChange}
          onEnterPressed={handleEnter}
          isValidated={cpfState === CPFState.VALID}
          error={cpfState === CPFState.INVALID}
          isLoading={isLoading}
        />
        <WavesImg src={UmeWaves} alt='ume-waves' />
      </DocumentContainer>
 
      <ConfirmContainer>
        <ConditionsAndTerms 
          handleCheckBox={handleCheckBox} 
          text={'Ao prosseguir você concorda com os '} 
          linkText={'termos de uso.'}
          link={UmeConfigs.CONDITIONS_AND_TERMS_URL}
        />

        <ConfirmButton
          disabled={cpfState !== CPFState.VALID || !isChecked || isLoading}
          onClick={handleEnter}
        >
          Começar
        </ConfirmButton>
      </ConfirmContainer>

      <LicenseText>Ume - Todos os direitos reservados ©</LicenseText>
    </Container>
  );
};

export default CpfPage;

const Container = styled.div`
  height: 100%;

  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  margin: 5%;
`;

const ConfirmContainer = styled.div`
text-align: center;

display: flex;
flex-direction: column;
justify-content: space-around;

margin: 2%;
`;

const DocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  margin: 0;
`;

const WavesImg = styled.img`
  height: 35px;
  width: 180px;

  align-self: end;

  margin-top: 2vh;
`;

const SubTitle = styled.span`
  color: ${Colors.gray};
`;

const LicenseText = styled.span``;

const ConfirmButton = styled.button`
  margin-top: 3vh;

  // BUTTON
  background: ${(props: any) =>
    props && props.disabled ? '#a9e1bc' : '#10A343'};
  border-radius: 10px;
  border: 0px;
  padding: 12px;

  // TEXT
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #ffffff;
`;
