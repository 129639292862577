import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import NextBackFooterButtons from '../layout/NextBackFooterButtons';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useAppDispatch } from '../redux/hooks';
import applicationReducer from '../redux/reducers/application.reducer';
import {
  OnboardingItemOne,
  OnboardingItemThree,
  OnboardingItemTwo,
} from './Onboarding/OnboardingItems';
import ApplicationFormResume from './Onboarding/ApplicationFormResume';
import { UrlParamsEnum } from '../common/enums';

let isButtonPressed = false;

const OnboardingPage = () => {
  const dispatch = useAppDispatch();
  const [pageIndex, setPageIndex] = useState<number>(0);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referralCode = urlParams.get(UrlParamsEnum.REFERRAL_CODE);

    if (referralCode) {
      dispatch(applicationReducer.actions.setReferralCode(referralCode));
    }
  }, [dispatch]);

  const handleNextPage = () => {
    if (isButtonPressed) return;
    isButtonPressed = true;

    const newPageIndex = pageIndex + 1;
    setPageIndex(newPageIndex);
    if (newPageIndex >= 4) {
      dispatch(applicationReducer.actions.nextPage());
    }

    setTimeout(() => (isButtonPressed = false), 500);
  };

  const handlePreviousPage = () => {
    const newPageIndex = pageIndex - 1;
    if (newPageIndex < 0) return;

    setPageIndex(newPageIndex);
  };

  const renderOnboardingItem = () => {
    if (pageIndex === 0) {
      return <OnboardingItemOne />;
    } else if (pageIndex === 1) {
      return <OnboardingItemTwo />;
    } else if (pageIndex === 2) {
      return <OnboardingItemThree />;
    } else {
      return <ApplicationFormResume />;
    }
  };

  const isFirstPage = pageIndex === 0;
  const isLastPage = pageIndex === 3;

  const renderButtonText = () => {
    if (isLastPage) {
      return 'Quero meu crédito UME';
    } else if (isFirstPage) {
      return 'Próximo';
    }

    return 'Próximo';
  };

  return (
    <PageContainer>
      {renderOnboardingItem()}

      <div style={{ margin: '8%' }}>
        <Footer>
          <NextBackFooterButtons
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            isNextButtonEnabled={true}
            labelNextButton={renderButtonText()}
            onlyNextButton={isLastPage || isFirstPage}
          />
        </Footer>
      </div>
    </PageContainer>
  );
};

export default OnboardingPage;

const PageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  // margin: 8%;
`;

const Footer = styled.div`
  // height: 8%;
  display: flex;
  flex-direction: row;
  width: 100%;
  // margin: 8%;

  // margin-left: 8%;
`;
