import React, { useState } from 'react';
import styled, { StyledProps } from 'styled-components';

interface Props {
    style?: StyledProps<any>;
    initialStatus?: boolean;
    onStatusChange?: (status: boolean) => void;
}

const CheckBox = (props: Props): JSX.Element => {
    const { initialStatus, style, onStatusChange } = props;
    const [enabled, setEnabled] = useState<boolean>(initialStatus || false);

    const handlePress = () => {
        setEnabled(!enabled);
        onStatusChange && onStatusChange(!enabled);
    }

    return (
        <Container
            style={style}
            onClick={handlePress}
        >
            <Box selected={enabled}>
                {enabled && (
                    <Check/>
                )}
            </Box>
        </Container>
    );
};

export default CheckBox;

const Container = styled.button`
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
`;

interface BoxProps {
    selected: boolean;
}
const Box = styled.div<BoxProps>`
    position: relative;
    width: 1px;
    height: 10px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => props.selected ? '#0DA852' : '#757E79'};
    justify-content: start;
    align-items: start;
`;

const Check = styled.div`
    position: relative;
    left: -5px; 
    width: 11px;
    height: 10px;
    background-color: #6CED7F;
    border-radius: 2px;
`;