import { CircularProgress } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

const LoadingPage = () => {
    return <Container>
        <CircularProgress />
    </Container>
}

export default LoadingPage

const Container = styled.div`
    align-items: center;
    align-self: center;
    margin: auto;
`
