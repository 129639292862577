import EventEmitter from "events"

class TimeoutTimer extends EventEmitter {
    static TICK_EVENT = 'tick'
    private timerRef: NodeJS.Timer | undefined = undefined
  
    private timeout: number | undefined = undefined
    private startedOnTimestamp: Date | undefined = undefined
  
    public secondsLeft: number | undefined = undefined
  
    startTimer(timeout: number) {
      this.timeout = timeout
      this.startedOnTimestamp = new Date()
  
      this.timerRef = setInterval( () => {
        if(!this.startedOnTimestamp || !this.timeout) return
  
        const currentTimestamp = new Date()
        const seconds = Math.trunc((currentTimestamp.getTime() - this.startedOnTimestamp?.getTime()) / 1000)
        this.secondsLeft = this.timeout - seconds
  
        this.emit(TimeoutTimer.TICK_EVENT, this.secondsLeft)
  
        if (seconds >= this.timeout) {
          return clearInterval(this.timerRef)
        }
      }, 1000)
    }
  }

export default TimeoutTimer