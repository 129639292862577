import React from 'react';
import UmeLogo from '../assets/ume_logo.png';
import styled from 'styled-components';

const MobileHeader = () => {
  return (
    <Header>
      <img src={UmeLogo} alt='UME Logo' height={'100%'} />
      <HeaderTitle> Cadastro UME </HeaderTitle>
    </Header>
  );
};

export default MobileHeader;

const Header = styled.div`
  height: 6%;
  background-color: black;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const HeaderTitle = styled.span`
  flex: 1;
  text-align: center;
  padding-right: 8%;
  color: white;
`;
