enum BiometryApiErrors {
    SUCCESS = "SUCCESS",
    UNSUPORTED_BASE64 = "UNSUPORTED_BASE64",
    FACE_NEEDS_CENTERING = "FACE_NEEDS_CENTERING",
    FACE_TOO_FAR = "FACE_TOO_FAR",
    FACE_TOO_CLOSE = "FACE_TOO_CLOSE",
    TOO_DARK = "TOO_DARK",
    OUT_OF_FOCUS = "OUT_OF_FOCUS",
    FACE_TILTED = "FACE_TILTED",
    FACE_WITH_GLASSES = "FACE_WITH_GLASSES",
    DOCUMENT_ALREADY_EXISTS = "DOCUMENT_ALREADY_EXISTS",
    UNKNOWN_DOCUMENT = "UNKNOWN_DOCUMENT",
    INVALID_DOCUMENT = "INVALID_DOCUMENT",
    UNKNOWN_ERROR = "UNKNOWN_ERROR",
    SERVICE_UNAVAILABLE = "SERVICE_UNAVAILABLE",
    BIOMETRY_NOT_FOUND = "BIOMETRY_NOT_FOUND",
    BIOMETRY_ALREADY_CONCLUDED = "PROCESS_ALREADY_CONCLUDED",
    INVALID_CPF = "INVALID_CPF",
    PERSON_NOT_FOUND = "PERSON_NOT_FOUND",
    CLIENT_NOT_FOUND = "CLIENT_NOT_FOUND",
    UNABLE_TOKEN = "UNABLE_TOKEN",
    SERVICE_UNAVAIBLE = "SERVICE_UNAVAIBLE",
    UNABLE_TO_CREATE_PROCESS = "UNABLE_TO_CREATE_PROCESS",
    WRONG_DOCUMENT_TYPE = "WRONG_DOCUMENT_TYPE",
    PROCESS_IN_PROGRESS_NOT_FOUND = "PROCESS_IN_PROGRESS_NOT_FOUND",
    SUCCESSFUL_FACE_IMAGE_NOT_FOUND = "SUCCESSFUL_FACE_IMAGE_NOT_FOUND",
    SUCCESSFUL_DOCUMENT_IMAGE_NOT_FOUND = "SUCCESSFUL_DOCUMENT_IMAGE_NOT_FOUND",
    BORROWER_ALREADY_HAS_PROCESS_IN_PROGRESS = "BORROWER_ALREADY_HAS_PROCESS_IN_PROGRESS",
    INVALID_IMAGE = "INVALID_IMAGE",
    INVALID_WDD_CREDENTIALS = "INVALID_WDD_CREDENTIALS",
    IMAGE_FILE_ERROR = "IMAGE_FILE_ERROR",
    FAILED_TO_REQUEST = "FAILED_TO_REQUEST",
    SPOOF_IMAGE = "SPOOF_IMAGE"
}

const biometryErrorsToString: { [message: string]: string } = {
    [BiometryApiErrors.SUCCESS]: 'Imagem sendo processada',
    [BiometryApiErrors.BIOMETRY_ALREADY_CONCLUDED]: 'Processo de biometria já realizado',
    [BiometryApiErrors.BIOMETRY_NOT_FOUND]: 'Processo de biometria não encontrado',
    [BiometryApiErrors.BORROWER_ALREADY_HAS_PROCESS_IN_PROGRESS]:
      'Já existe um processo de biometria em andamento.',
    [BiometryApiErrors.CLIENT_NOT_FOUND]: 'Cliente não encontrado.',
    [BiometryApiErrors.DOCUMENT_ALREADY_EXISTS]: 'Documento já registrado',
    [BiometryApiErrors.FACE_NEEDS_CENTERING]: 'Centralize o rosto',
    [BiometryApiErrors.FACE_TILTED]: 'Imagem borrada',
    [BiometryApiErrors.FACE_TOO_CLOSE]: 'Rosto próximo demais',
    [BiometryApiErrors.FACE_TOO_FAR]: 'Rosto longe demais',
    [BiometryApiErrors.FACE_WITH_GLASSES]: 'Rosto com óculos',
    [BiometryApiErrors.INVALID_CPF]: 'CPF inválido',
    [BiometryApiErrors.INVALID_DOCUMENT]: 'Documento inválido',
    [BiometryApiErrors.OUT_OF_FOCUS]: 'Imagem sem foco',
    [BiometryApiErrors.PERSON_NOT_FOUND]: 'Score biométrico não encontrado.',
    [BiometryApiErrors.PROCESS_IN_PROGRESS_NOT_FOUND]: 'Biometria não encontrada. Tire outra foto de rosto.',
    [BiometryApiErrors.SERVICE_UNAVAIBLE]: 'Serviço indisponível.',
    [BiometryApiErrors.SERVICE_UNAVAILABLE]: 'Serviço indisponível.',
    [BiometryApiErrors.SUCCESSFUL_DOCUMENT_IMAGE_NOT_FOUND]: 'Documento com sucesso não encontrada.',
    [BiometryApiErrors.SUCCESSFUL_FACE_IMAGE_NOT_FOUND]: 'Imagem de rosto com sucesso não encontrada.',
    [BiometryApiErrors.TOO_DARK]: 'Imagem muito escura',
    [BiometryApiErrors.UNABLE_TOKEN]: 'Token inválido.',
    [BiometryApiErrors.UNABLE_TO_CREATE_PROCESS]: 'Incapaz de criar processo.',
    [BiometryApiErrors.UNKNOWN_DOCUMENT]: 'Documento desconhecido',
    [BiometryApiErrors.UNKNOWN_ERROR]: 'Erro desconhecido',
    [BiometryApiErrors.UNSUPORTED_BASE64]: 'Formato de imagem não suportado',
    [BiometryApiErrors.WRONG_DOCUMENT_TYPE]: 'Documento do tipo incorreto',
    [BiometryApiErrors.INVALID_IMAGE]: 'Imagem inválida. Tente novamente.',
    [BiometryApiErrors.INVALID_WDD_CREDENTIALS]: 'Credenciais de parceiro inválidas. Contate a Engenharia.', // Unlikely to occurr
    [BiometryApiErrors.IMAGE_FILE_ERROR]: 'Erro ao fazer upload da imagem. Tente novamente.',
    [BiometryApiErrors.FAILED_TO_REQUEST]: 'Serviço indisponível. Tente novamente ou contate a engenharia.',
    [BiometryApiErrors.SPOOF_IMAGE]: 'Não identificamos uma pessoa ao vivo na foto. Tente novamente com uma foto do cliente ao vivo.'
  }
  

export default class BiometryErrorsMapper {
    static mapErrorToUIString(error: string) {
        // return error
        const mappedError = biometryErrorsToString[error]

        if(!mappedError) {
          return 'Erro desconhecido. Tente novamente.'
        }

        return mappedError
    }
}