import { CircularProgress } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

interface Props {
    handleResendCode: () => void
    secondsToSendSMS: number
    isDisabled?: boolean
    handleChangePhoneNumberClick: () => void
    isLoading?: boolean
}

const ResendVerificationCodeTimer = ({handleResendCode, secondsToSendSMS, isDisabled, handleChangePhoneNumberClick, isLoading}: Props) => {
    if(isDisabled) {
      return (<Container style={{visibility: 'hidden'}}>
          <ClickableText>Reenviar código</ClickableText>
          <ClickableText style={{marginTop: '2vh'}} >Trocar telefone</ClickableText>
      </Container>)
    }

    if(isLoading) {
      return(
        <Container>
            <ResendCodeContainer>
              <BlueText onClick={handleResendCode}>Reenviando o código</BlueText>
              <CircularProgress size={18} />
            </ResendCodeContainer>
            <ClickableText onClick={handleChangePhoneNumberClick} style={{marginTop: '2vh'}} >Trocar telefone</ClickableText>
        </Container>
      )
    }

    if(secondsToSendSMS === 0) {
        return(
            <Container>
                <ClickableText onClick={handleResendCode}>Reenviar código</ClickableText>
                <ClickableText onClick={handleChangePhoneNumberClick} style={{marginTop: '2vh'}} >Trocar telefone</ClickableText>
            </Container>
        )    
    }

    return(
        <Container>
            <ResendCodeContainer>
              <BlueText onClick={handleResendCode} disabled={true}>Reenviar código em</BlueText>
              <Timer>{secondsToSendSMS} segundos</Timer>
            </ResendCodeContainer>
            <ClickableText onClick={handleChangePhoneNumberClick} disabled={true} style={{marginTop: '2vh'}} >Trocar telefone</ClickableText>
        </Container>
    )
}

export default ResendVerificationCodeTimer

const Container = styled.div` 
  display: flex;
  flex-direction: column;
  justify-content: space-between;

//   TODO(lucas.citolin): remove
  margin-top: 4vh;
`

const ResendCodeContainer = styled.div` 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const BlueText = styled.span<{ disabled?: boolean }>`
  // font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  color: #0E2DE6;

  letter-spacing: 0.03em;
  ${(props: any) => (props && props.disabled ? "opacity: 0.3; pointer-events: none;" : "")};
`

const ClickableText = styled(BlueText)`
  text-decoration: underline;
`


const Timer = styled.span`

`
