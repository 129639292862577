import moment from 'moment'

export class PersonUtils {
    static nameWithLastNameRegex = /.*\s.+/

    static validateName(name: string): boolean {
      return true
    }

    static sanitizeName(name: string): string {
      return name.replace(/[&/\\#,+()$~%.'":*?<>{}\\-\\_=;|0-9]/g, '')
    }

    static validateNameWithLastName(name?: string): boolean {
      if (!name) {
        return false
      }

      return this.nameWithLastNameRegex.test(name)
    }

    static validateBirthDate(birthDate?: string) {
      if (!birthDate) {
        return {
          isBirthDateValid: false,
          errorText: '',
        }
      }
    
      let isBirthDateValid = moment(birthDate, 'DD/MM/YYYY', true).isValid()
    
      if (isBirthDateValid) {
        const formattedDate = moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        const date = moment(formattedDate, 'YYYY-MM-DD').toDate()
    
        if (moment(date).isAfter()) {
          return {
            isBirthDateValid: false,
            errorText: 'Idade mínima 18 anos de idade',
          }
        }
    
        const diffYear = moment().diff(date, 'year')
    
        if (diffYear < 18) {
          return {
            isBirthDateValid: false,
            errorText: 'Idade mínima 18 anos de idade',
          }
        }
    
        if (diffYear >= 100) {
          return {
            isBirthDateValid: false,
            errorText: 'Idade máxima 100 anos de idade',
          }
        }
      }
    
      return {
        isBirthDateValid,
        errorText: '',
      }
    }
    
}