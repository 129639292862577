// https://github.com/mui-org/material-ui/issues/19382
import { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete'

export interface ApplicationProfession {
  name: string
  id: number
}

const defaultFilterOptions = createFilterOptions<ApplicationProfession>()
export const applicationProfessionsFilterOptions = (
  options: ApplicationProfession[],
  state: FilterOptionsState<ApplicationProfession>
) => defaultFilterOptions(options, state).slice(0, 30)

export const APPLICATION_PROFESSIONS = [
  {
    name: 'OUTROS',
    id: 1,
  },
  {
    name: 'ABASTECEDOR DE MONTAGEM',
    id: 2,
  },
  {
    name: 'ABASTECEDOR(A)',
    id: 3,
  },
  {
    name: 'ABATEDOR',
    id: 4,
  },
  {
    name: 'ACABADOR DE SUPERFÍCIES DE CONCRETO ARMADO',
    id: 5,
  },
  {
    name: 'ASSESSOR COMERCIAL',
    id: 6,
  },
  {
    name: 'ASSESSOR JURIDICO',
    id: 7,
  },
  {
    name: 'ASSESSOR PARLAMENTAR',
    id: 8,
  },
  {
    name: 'ASSESSORA COMERCIAL',
    id: 9,
  },
  {
    name: 'ASSESSORA DE GABINETE',
    id: 10,
  },
  {
    name: 'ACOGUEIRA',
    id: 11,
  },
  {
    name: 'ACOGUEIRO',
    id: 12,
  },
  {
    name: 'ACOMPANHANTE',
    id: 13,
  },
  {
    name: 'ACOUGUEIRO',
    id: 14,
  },
  {
    name: 'ACOUGUEIRO (A)',
    id: 15,
  },
  {
    name: 'ADMINISTRACAO DE CAIXA',
    id: 16,
  },
  {
    name: 'ADMINISTRACAO FINANCEIRO',
    id: 17,
  },
  {
    name: 'ADMINISTRADOR (A)',
    id: 18,
  },
  {
    name: 'ADMINISTRADOR DE EMPRESAS',
    id: 19,
  },
  {
    name: 'ADMINISTRADOR DE IMOVEIS',
    id: 20,
  },
  {
    name: 'ADMINISTRADOR DE VENDAS',
    id: 21,
  },
  {
    name: 'ADMINISTRADOR FINANCEIRO',
    id: 22,
  },
  {
    name: 'ADMINISTRADOR(A)',
    id: 23,
  },
  {
    name: 'ADMINISTRADORA',
    id: 24,
  },
  {
    name: 'ADMINISTRATIVO',
    id: 25,
  },
  {
    name: 'ADOLESC APRENDIZ EDUC P/ FUTURO',
    id: 26,
  },
  {
    name: 'ADVOGADA',
    id: 27,
  },
  {
    name: 'ADVOGADO',
    id: 28,
  },
  {
    name: 'AFASTADO PELO INSS',
    id: 29,
  },
  {
    name: 'AFIADOR DE FERRAMENTAS',
    id: 30,
  },
  {
    name: 'AGENCIADOR',
    id: 31,
  },
  {
    name: 'AGENTE ADMINISTRATIVO',
    id: 32,
  },
  {
    name: 'AGENTE COMERCIAL',
    id: 33,
  },
  {
    name: 'AGENTE COMUNITARIA',
    id: 34,
  },
  {
    name: 'AGENTE COMUNITARIO',
    id: 35,
  },
  {
    name: 'AGENTE COMUNITARIO DE SAUDE',
    id: 36,
  },
  {
    name: 'AGENTE DE AEROPORTO',
    id: 37,
  },
  {
    name: 'AGENTE DE APOIO',
    id: 38,
  },
  {
    name: 'AGENTE DE ATENDIMENTO',
    id: 39,
  },
  {
    name: 'AGENTE DE CARGA',
    id: 40,
  },
  {
    name: 'AGENTE DE COBRANCA',
    id: 41,
  },
  {
    name: 'AGENTE DE CONSERVACAO',
    id: 42,
  },
  {
    name: 'AGENTE DE CONTROLE DE VETORES',
    id: 43,
  },
  {
    name: 'AGENTE DE EVENTOS',
    id: 44,
  },
  {
    name: 'AGENTE DE NEGOCIOS',
    id: 45,
  },
  {
    name: 'AGENTE DE PESQUISA',
    id: 46,
  },
  {
    name: 'AGENTE DE POLICIA',
    id: 47,
  },
  {
    name: 'AGENTE DE PORTARIA',
    id: 48,
  },
  {
    name: 'AGENTE DE SAUDE',
    id: 49,
  },
  {
    name: 'AGENTE DE SAÚDE PÚBLICA',
    id: 50,
  },
  {
    name: 'AGENTE DE SEGURANCA',
    id: 51,
  },
  {
    name: 'AGENTE DE SERVICO TECNICO',
    id: 52,
  },
  {
    name: 'AGENTE DE SERVICOS GERAIS',
    id: 53,
  },
  {
    name: 'AGENTE DE TELE COBRANCA',
    id: 54,
  },
  {
    name: 'AGENTE DE TRANSITO',
    id: 55,
  },
  {
    name: 'AGENTE DE TURISMO',
    id: 56,
  },
  {
    name: 'AGENTE DE VENDA DE SERVIÇOS ÀS EMPRESAS',
    id: 57,
  },
  {
    name: 'AGENTE DE VENDAS',
    id: 58,
  },
  {
    name: 'AGENTE DE VIAGEM',
    id: 59,
  },
  {
    name: 'AGENTE FUNERÁRIO',
    id: 60,
  },
  {
    name: 'AGENTE OPERACIONAL',
    id: 61,
  },
  {
    name: 'AGENTE PENITENCIARIO',
    id: 62,
  },
  {
    name: 'AGENTE PRISIONAL',
    id: 63,
  },
  {
    name: 'AGENTE SERVICOS ADMINISTRATIVOS',
    id: 64,
  },
  {
    name: 'AGENTE SERVICOS GERAIS',
    id: 65,
  },
  {
    name: 'AGENTE SOCIAL',
    id: 66,
  },
  {
    name: 'AGENTE TECNICO',
    id: 67,
  },
  {
    name: 'AGENTE TECNICO ADMINISTRATIVO',
    id: 68,
  },
  {
    name: 'AGENTE TECNICO DE PRODUCAO',
    id: 69,
  },
  {
    name: 'AGENTE TECNICO DE VENDAS',
    id: 70,
  },
  {
    name: 'AGRICULTOR',
    id: 71,
  },
  {
    name: 'AGRICULTOR(A)',
    id: 72,
  },
  {
    name: 'AGRONOMO',
    id: 73,
  },
  {
    name: 'AGROPECUARISTA',
    id: 74,
  },
  {
    name: 'AJUDANTE',
    id: 75,
  },
  {
    name: 'AJUDANTE DE BOMBA',
    id: 76,
  },
  {
    name: 'AJUDANTE DE CARGA',
    id: 77,
  },
  {
    name: 'AJUDANTE DE CONFEITEIRO',
    id: 78,
  },
  {
    name: 'AJUDANTE DE DISTRIBUICAO',
    id: 79,
  },
  {
    name: 'AJUDANTE DE ELETRICISTA',
    id: 80,
  },
  {
    name: 'AJUDANTE DE ENTREGA',
    id: 81,
  },
  {
    name: 'AJUDANTE DE EXPEDICAO',
    id: 82,
  },
  {
    name: 'AJUDANTE DE FABRICAÇAO',
    id: 83,
  },
  {
    name: 'AJUDANTE DE LAVANDERIA',
    id: 84,
  },
  {
    name: 'AJUDANTE DE MARCENEIRO',
    id: 85,
  },
  {
    name: 'AJUDANTE DE MOTORISTA',
    id: 86,
  },
  {
    name: 'AJUDANTE DE PEDREIRO',
    id: 87,
  },
  {
    name: 'AJUDANTE DE PRODUCAO',
    id: 88,
  },
  {
    name: 'AJUDANTE DE ZELADORIA',
    id: 89,
  },
  {
    name: 'AJUDANTE GERAL',
    id: 90,
  },
  {
    name: 'AJUDANTE MECANICO',
    id: 91,
  },
  {
    name: 'AJUSTADOR MECANICO',
    id: 92,
  },
  {
    name: 'AJUSTADOR MECÂNICO',
    id: 93,
  },
  {
    name: 'AJUSTADOR MONTADOR',
    id: 94,
  },
  {
    name: 'ALIMENTADOR DE LINHA DE PRODUÇAO',
    id: 95,
  },
  {
    name: 'ALINHADOR DE PNEUS',
    id: 96,
  },
  {
    name: 'ALINHADOR DE RODAS',
    id: 97,
  },
  {
    name: 'ALMOXARIFE',
    id: 98,
  },
  {
    name: 'ALMOXERIFADO',
    id: 99,
  },
  {
    name: 'ALUGUEL',
    id: 100,
  },
  {
    name: 'ANALISTA',
    id: 101,
  },
  {
    name: 'ANALISTA ADMINISTRATIVO',
    id: 102,
  },
  {
    name: 'ANALISTA COMERCIAL',
    id: 103,
  },
  {
    name: 'ANALISTA CONSULTOR',
    id: 104,
  },
  {
    name: 'ANALISTA CONTABIL',
    id: 105,
  },
  {
    name: 'ANALISTA DE ADMINISTRACAO PESSOAL',
    id: 106,
  },
  {
    name: 'ANALISTA DE COBRANCA',
    id: 107,
  },
  {
    name: 'ANALISTA DE CONTROLADORIA',
    id: 108,
  },
  {
    name: 'ANALISTA DE CREDITO',
    id: 109,
  },
  {
    name: 'ANALISTA DE CREDITO E COBRANCA',
    id: 110,
  },
  {
    name: 'ANALISTA DE ESTOQUE',
    id: 111,
  },
  {
    name: 'ANALISTA DE FINANCEIRO',
    id: 112,
  },
  {
    name: 'ANALISTA DE INFORMATICA',
    id: 113,
  },
  {
    name: 'ANALISTA DE LABORATORIO',
    id: 114,
  },
  {
    name: 'ANALISTA DE LOGISTICA',
    id: 115,
  },
  {
    name: 'ANALISTA DE MANUTENCAO',
    id: 116,
  },
  {
    name: 'ANALISTA DE MARKETING',
    id: 117,
  },
  {
    name: 'ANALISTA DE OPERACOES',
    id: 118,
  },
  {
    name: 'ANALISTA DE PLANEJAMENTO',
    id: 119,
  },
  {
    name: 'ANALISTA DE PRODUCAO',
    id: 120,
  },
  {
    name: 'ANALISTA DE QUALIDADE',
    id: 121,
  },
  {
    name: 'ANALISTA DE RECURSOS HUMANOS',
    id: 122,
  },
  {
    name: 'ANALISTA DE RH',
    id: 123,
  },
  {
    name: 'ANALISTA DE SISTEMAS',
    id: 124,
  },
  {
    name: 'ANALISTA DE SUPORTE',
    id: 125,
  },
  {
    name: 'ANALISTA DE TECNOLOGIA DA INFORMACAO',
    id: 126,
  },
  {
    name: 'ANALISTA DE TELECOMUNICACOES',
    id: 127,
  },
  {
    name: 'ANALISTA DE VENDAS',
    id: 128,
  },
  {
    name: 'ANALISTA FINANCEIRO',
    id: 129,
  },
  {
    name: 'ANALISTA FISCAL',
    id: 130,
  },
  {
    name: 'ANALISTA JUDICIARIO',
    id: 131,
  },
  {
    name: 'ANALISTA TECNICO',
    id: 132,
  },
  {
    name: 'APLICADOR DE INSULFILMES',
    id: 133,
  },
  {
    name: 'APOIO',
    id: 134,
  },
  {
    name: 'APONTADOR DE MAODEOBRA',
    id: 135,
  },
  {
    name: 'APONTADOR DE PRODUÇAO',
    id: 136,
  },
  {
    name: 'APOSENTADA',
    id: 137,
  },
  {
    name: 'APOSENTADA E DOMESTICA',
    id: 138,
  },
  {
    name: 'APOSENTADO',
    id: 139,
  },
  {
    name: 'APOSENTADO E ADVOGADO',
    id: 140,
  },
  {
    name: 'APOSENTADO E AUXILIAR DE ESCRITORIO',
    id: 141,
  },
  {
    name: 'APRENDIZ DE ELETRICISTA',
    id: 142,
  },
  {
    name: 'ARMADOR DE CONCRETO ARMADO',
    id: 143,
  },
  {
    name: 'ARMADOR DE ESTRUTURA DE CONCRETO',
    id: 144,
  },
  {
    name: 'ARMADOR DE FERRAGENS',
    id: 145,
  },
  {
    name: 'ARMAZENISTA',
    id: 146,
  },
  {
    name: 'ARQUITETA',
    id: 147,
  },
  {
    name: 'ARQUITETO',
    id: 148,
  },
  {
    name: 'ARQUIVISTA',
    id: 149,
  },
  {
    name: 'ARRUMADEIRA',
    id: 150,
  },
  {
    name: 'ARRUMADOR',
    id: 151,
  },
  {
    name: 'ARTE FINALISTA',
    id: 152,
  },
  {
    name: 'ARTE GRAFICO',
    id: 153,
  },
  {
    name: 'ARTESA',
    id: 154,
  },
  {
    name: 'ARTESAN',
    id: 155,
  },
  {
    name: 'ARTESAO',
    id: 156,
  },
  {
    name: 'ARTEZA',
    id: 157,
  },
  {
    name: 'ARTIFICE',
    id: 158,
  },
  {
    name: 'ARTISTA PLASTICA',
    id: 159,
  },
  {
    name: 'ASSESOR DE CONTROLE INTERNO',
    id: 160,
  },
  {
    name: 'ASSESSOR',
    id: 161,
  },
  {
    name: 'ASSESSOR COMERCIAL',
    id: 162,
  },
  {
    name: 'ASSESSOR JURIDICO',
    id: 163,
  },
  {
    name: 'ASSESSOR PARLAMENTAR',
    id: 164,
  },
  {
    name: 'ASSESSOR TECNICO',
    id: 165,
  },
  {
    name: 'ASSESSORA',
    id: 166,
  },
  {
    name: 'ASSESSORA DE GABINETE',
    id: 167,
  },
  {
    name: 'ASSESSORA DE VENDAS',
    id: 168,
  },
  {
    name: 'ASSESSORA ESPECIAL',
    id: 169,
  },
  {
    name: 'ASSISTENCIA DE MONITORAMENTO',
    id: 170,
  },
  {
    name: 'ASSISTENTE ADMINISTRATIVO',
    id: 171,
  },
  {
    name: 'ASSISTENTE ADMINISTRATIVO BILINGUE JUNIOR',
    id: 172,
  },
  {
    name: 'ASSISTENTE ATENDIMENTO',
    id: 173,
  },
  {
    name: 'ASSISTENTE COMERCIAL',
    id: 174,
  },
  {
    name: 'ASSISTENTE CONTABIL',
    id: 175,
  },
  {
    name: 'ASSISTENTE DE ATENDIMENTO',
    id: 176,
  },
  {
    name: 'ASSISTENTE DE BACKOFFICE',
    id: 177,
  },
  {
    name: 'ASSISTENTE DE COBRANCA',
    id: 178,
  },
  {
    name: 'ASSISTENTE DE COMPRAS',
    id: 179,
  },
  {
    name: 'ASSISTENTE DE CONTROLADORIA',
    id: 180,
  },
  {
    name: 'ASSISTENTE DE DEPARTAMENTO PESSOAL',
    id: 181,
  },
  {
    name: 'ASSISTENTE DE DESING',
    id: 182,
  },
  {
    name: 'ASSISTENTE DE EDUCACAO',
    id: 183,
  },
  {
    name: 'ASSISTENTE DE FATURAMENTO',
    id: 184,
  },
  {
    name: 'ASSISTENTE DE GERENCIA',
    id: 185,
  },
  {
    name: 'ASSISTENTE DE LABORATORIO',
    id: 186,
  },
  {
    name: 'ASSISTENTE DE LOGISTICA',
    id: 187,
  },
  {
    name: 'ASSISTENTE DE MARKETING',
    id: 188,
  },
  {
    name: 'ASSISTENTE DE NEGOCIOS',
    id: 189,
  },
  {
    name: 'ASSISTENTE DE OPERACOES',
    id: 190,
  },
  {
    name: 'ASSISTENTE DE PADARIA',
    id: 191,
  },
  {
    name: 'ASSISTENTE DE PCP',
    id: 192,
  },
  {
    name: 'ASSISTENTE DE PEDAGOGIA',
    id: 193,
  },
  {
    name: 'ASSISTENTE DE PRODUCAO',
    id: 194,
  },
  {
    name: 'ASSISTENTE DE PUBLICIDADE',
    id: 195,
  },
  {
    name: 'ASSISTENTE DE QUALIDADE',
    id: 196,
  },
  {
    name: 'ASSISTENTE DE RH',
    id: 197,
  },
  {
    name: 'ASSISTENTE DE SAUDE',
    id: 198,
  },
  {
    name: 'ASSISTENTE DE SUPORTE TECNICO',
    id: 199,
  },
  {
    name: 'ASSISTENTE DE TESOURARIA',
    id: 200,
  },
  {
    name: 'ASSISTENTE DE TI',
    id: 201,
  },
  {
    name: 'ASSISTENTE DE TRANSPORTES',
    id: 202,
  },
  {
    name: 'ASSISTENTE DE VENDAS',
    id: 203,
  },
  {
    name: 'ASSISTENTE DE VENDAS (FINANCEIRO)',
    id: 204,
  },
  {
    name: 'ASSISTENTE EDUCACIONAL IV',
    id: 205,
  },
  {
    name: 'ASSISTENTE FINANCEIRO',
    id: 206,
  },
  {
    name: 'ASSISTENTE FISCAL',
    id: 207,
  },
  {
    name: 'ASSISTENTE JURIDICO',
    id: 208,
  },
  {
    name: 'ASSISTENTE OPERACIONAL',
    id: 209,
  },
  {
    name: 'ASSISTENTE PARLAMENTAR',
    id: 210,
  },
  {
    name: 'ASSISTENTE PESSOAL',
    id: 211,
  },
  {
    name: 'ASSISTENTE SOCIAL',
    id: 212,
  },
  {
    name: 'ASSISTENTE TECNICO(A)',
    id: 213,
  },
  {
    name: 'ATELIER DE COSTURA',
    id: 214,
  },
  {
    name: 'ATENDENTE',
    id: 215,
  },
  {
    name: 'ATENDENTE COMERCIAL',
    id: 216,
  },
  {
    name: 'ATENDENTE DE CONSULTORIO',
    id: 217,
  },
  {
    name: 'ATENDENTE DE CRECHE',
    id: 218,
  },
  {
    name: 'ATENDENTE DE CREDITO',
    id: 219,
  },
  {
    name: 'ATENDENTE DE ENFERMAGEM',
    id: 220,
  },
  {
    name: 'ATENDENTE DE FARMACIA',
    id: 221,
  },
  {
    name: 'ATENDENTE DE LANCHONETE',
    id: 222,
  },
  {
    name: 'ATENDENTE DE LAVANDERIA',
    id: 223,
  },
  {
    name: 'ATENDENTE DE LOJA',
    id: 224,
  },
  {
    name: 'ATENDENTE DE RESTAURANTE',
    id: 225,
  },
  {
    name: 'ATENDENTE DE SUPERMERCADO',
    id: 226,
  },
  {
    name: 'ATENDENTE DE TELEMARKETING',
    id: 227,
  },
  {
    name: 'ATENDENTE DE VENDAS',
    id: 228,
  },
  {
    name: 'ATENDENTE E TELE OPERADORA',
    id: 229,
  },
  {
    name: 'ATENDENTE ESCOLAR',
    id: 230,
  },
  {
    name: 'ATENDENTE INFANTIL',
    id: 231,
  },
  {
    name: 'ATENDIMENTO AO CLIENTE',
    id: 232,
  },
  {
    name: 'ATLETA',
    id: 233,
  },
  {
    name: 'ATLETA PROFISSIONAL DE FUTEBOL',
    id: 234,
  },
  {
    name: 'AUDITOR',
    id: 235,
  },
  {
    name: 'AUTONOMA',
    id: 236,
  },
  {
    name: 'AUTONOMO',
    id: 237,
  },
  {
    name: 'AUX. CONTROLE DE QUALIDADE',
    id: 238,
  },
  {
    name: 'AUXILAR DE ATENDIMENTO',
    id: 239,
  },
  {
    name: 'AUXILIAR',
    id: 240,
  },
  {
    name: 'AUXILIAR ADMINISTRATIVO',
    id: 241,
  },
  {
    name: 'AUXILIAR ADMINISTRATIVO ESCOLAR',
    id: 242,
  },
  {
    name: 'AUXILIAR ADMINISTRATIVO II',
    id: 243,
  },
  {
    name: 'AUXILIAR ADMINISTRATIVO III',
    id: 244,
  },
  {
    name: 'AUXILIAR COMERCIAL',
    id: 245,
  },
  {
    name: 'AUXILIAR CONSULTORIO DENTARIO',
    id: 246,
  },
  {
    name: 'AUXILIAR CONTABIL',
    id: 247,
  },
  {
    name: 'AUXILIAR CONTROLADOR INSPECAO',
    id: 248,
  },
  {
    name: 'AUXILIAR DE ACABAMENTO',
    id: 249,
  },
  {
    name: 'AUXILIAR DE ALMOXARIFADO',
    id: 250,
  },
  {
    name: 'AUXILIAR DE ARMAZEM',
    id: 251,
  },
  {
    name: 'AUXILIAR DE ATENDIMENTO',
    id: 252,
  },
  {
    name: 'AUXILIAR DE BIBLIOTECA',
    id: 253,
  },
  {
    name: 'AUXILIAR DE CABELEIREIRO',
    id: 254,
  },
  {
    name: 'AUXILIAR DE CAIXA',
    id: 255,
  },
  {
    name: 'AUXILIAR DE CALDEIRARIA',
    id: 256,
  },
  {
    name: 'AUXILIAR DE CARGA',
    id: 257,
  },
  {
    name: 'AUXILIAR DE CARTORIO',
    id: 258,
  },
  {
    name: 'AUXILIAR DE CLASSIFICACAO',
    id: 259,
  },
  {
    name: 'AUXILIAR DE COBRANCA',
    id: 260,
  },
  {
    name: 'AUXILIAR DE COMPRAS',
    id: 261,
  },
  {
    name: 'AUXILIAR DE CONFECCAO',
    id: 262,
  },
  {
    name: 'AUXILIAR DE CONFEITEIRO',
    id: 263,
  },
  {
    name: 'AUXILIAR DE CONSTRUCAO',
    id: 264,
  },
  {
    name: 'AUXILIAR DE CONSULTORIO',
    id: 265,
  },
  {
    name: 'AUXILIAR DE CONSULTORIO DENTARIO',
    id: 266,
  },
  {
    name: 'AUXILIAR DE CONTABILIDADE',
    id: 267,
  },
  {
    name: 'AUXILIAR DE CONTROLE DE QUALIDADE',
    id: 268,
  },
  {
    name: 'AUXILIAR DE COORDENACAO',
    id: 269,
  },
  {
    name: 'AUXILIAR DE COPA',
    id: 270,
  },
  {
    name: 'AUXILIAR DE CORTE',
    id: 271,
  },
  {
    name: 'AUXILIAR DE COSTURA',
    id: 272,
  },
  {
    name: 'AUXILIAR DE COZINHA',
    id: 273,
  },
  {
    name: 'AUXILIAR DE CREDITO E CADASTRO',
    id: 274,
  },
  {
    name: 'AUXILIAR DE CREDITO E COBRANCA',
    id: 275,
  },
  {
    name: 'AUXILIAR DE DENTISTA',
    id: 276,
  },
  {
    name: 'AUXILIAR DE DEPARTAMENTO PESSOAL',
    id: 277,
  },
  {
    name: 'AUXILIAR DE DEPOSITO',
    id: 278,
  },
  {
    name: 'AUXILIAR DE DESENVOLVIMENTO INFANTIL',
    id: 279,
  },
  {
    name: 'AUXILIAR DE DESINER',
    id: 280,
  },
  {
    name: 'AUXILIAR DE EDUCACAO',
    id: 281,
  },
  {
    name: 'AUXILIAR DE ELETRECISTA',
    id: 282,
  },
  {
    name: 'AUXILIAR DE ENFERMAGEM',
    id: 283,
  },
  {
    name: 'AUXILIAR DE ENFERMAGEM DO TRABALHO',
    id: 284,
  },
  {
    name: 'AUXILIAR DE ENGARREGADO',
    id: 285,
  },
  {
    name: 'AUXILIAR DE ENGENHARIA',
    id: 286,
  },
  {
    name: 'AUXILIAR DE ENTREGA',
    id: 287,
  },
  {
    name: 'AUXILIAR DE ESCRITA FISCAL',
    id: 288,
  },
  {
    name: 'AUXILIAR DE ESCRITORIO',
    id: 289,
  },
  {
    name: 'AUXILIAR DE ESTAMPARIA',
    id: 290,
  },
  {
    name: 'AUXILIAR DE ESTOQUE',
    id: 291,
  },
  {
    name: 'AUXILIAR DE EVENTOS',
    id: 292,
  },
  {
    name: 'AUXILIAR DE EXPEDICAO',
    id: 293,
  },
  {
    name: 'AUXILIAR DE FARMACIA',
    id: 294,
  },
  {
    name: 'AUXILIAR DE FARMÁCIA',
    id: 295,
  },
  {
    name: 'AUXILIAR DE FATURAMENTO',
    id: 296,
  },
  {
    name: 'AUXILIAR DE FISCAL DE CAIXA',
    id: 297,
  },
  {
    name: 'AUXILIAR DE FUNILARIA',
    id: 298,
  },
  {
    name: 'AUXILIAR DE GERENTE',
    id: 299,
  },
  {
    name: 'AUXILIAR DE HIGIENE',
    id: 300,
  },
  {
    name: 'AUXILIAR DE IMPRESSORA',
    id: 301,
  },
  {
    name: 'AUXILIAR DE INFORMATICA',
    id: 302,
  },
  {
    name: 'AUXILIAR DE INSTALACOES',
    id: 303,
  },
  {
    name: 'AUXILIAR DE JARDINEIRO',
    id: 304,
  },
  {
    name: 'AUXILIAR DE LABORATORIO',
    id: 305,
  },
  {
    name: 'AUXILIAR DE LAVANDERIA',
    id: 306,
  },
  {
    name: 'AUXILIAR DE LIMPEZA',
    id: 307,
  },
  {
    name: 'AUXILIAR DE LIMPEZA PREDIAL',
    id: 308,
  },
  {
    name: 'AUXILIAR DE LOJA',
    id: 309,
  },
  {
    name: 'AUXILIAR DE MANIPULACAO',
    id: 310,
  },
  {
    name: 'AUXILIAR DE MANUFATURA',
    id: 311,
  },
  {
    name: 'AUXILIAR DE MANUTENCAO',
    id: 312,
  },
  {
    name: 'AUXILIAR DE MAQUINA',
    id: 313,
  },
  {
    name: 'AUXILIAR DE MARCENEIRO',
    id: 314,
  },
  {
    name: 'AUXILIAR DE MARMORISTA',
    id: 315,
  },
  {
    name: 'AUXILIAR DE MECANICA',
    id: 316,
  },
  {
    name: 'AUXILIAR DE MECANICO',
    id: 317,
  },
  {
    name: 'AUXILIAR DE MONTAGEM',
    id: 318,
  },
  {
    name: 'AUXILIAR DE MOTORISTA',
    id: 319,
  },
  {
    name: 'AUXILIAR DE NUTRICAO',
    id: 320,
  },
  {
    name: 'AUXILIAR DE ODONTOLOGIA',
    id: 321,
  },
  {
    name: 'AUXILIAR DE OPERACAO',
    id: 322,
  },
  {
    name: 'AUXILIAR DE OURIVES',
    id: 323,
  },
  {
    name: 'AUXILIAR DE PADEIRO',
    id: 324,
  },
  {
    name: 'AUXILIAR DE PERECIVEIS',
    id: 325,
  },
  {
    name: 'AUXILIAR DE PESSOAL',
    id: 326,
  },
  {
    name: 'AUXILIAR DE PINTOR',
    id: 327,
  },
  {
    name: 'AUXILIAR DE PINTURA',
    id: 328,
  },
  {
    name: 'AUXILIAR DE PROCESSAMENTO',
    id: 329,
  },
  {
    name: 'AUXILIAR DE PRODUCAO',
    id: 330,
  },
  {
    name: 'AUXILIAR DE PROTESE',
    id: 331,
  },
  {
    name: 'AUXILIAR DE QUALIDADE',
    id: 332,
  },
  {
    name: 'AUXILIAR DE RAMPA',
    id: 333,
  },
  {
    name: 'AUXILIAR DE RECICLAGEM',
    id: 334,
  },
  {
    name: 'AUXILIAR DE RECURSOS HUMANOS',
    id: 335,
  },
  {
    name: 'AUXILIAR DE REVISAO',
    id: 336,
  },
  {
    name: 'AUXILIAR DE RH',
    id: 337,
  },
  {
    name: 'AUXILIAR DE SALA',
    id: 338,
  },
  {
    name: 'AUXILIAR DE SAUDE',
    id: 339,
  },
  {
    name: 'AUXILIAR DE SECRETARIA',
    id: 340,
  },
  {
    name: 'AUXILIAR DE SERIGRAFIA',
    id: 341,
  },
  {
    name: 'AUXILIAR DE SERRALHEIRO',
    id: 342,
  },
  {
    name: 'AUXILIAR DE SERVICO INTERNO/EXTERNO',
    id: 343,
  },
  {
    name: 'AUXILIAR DE SERVICOS ADMINISTRATIVOS',
    id: 344,
  },
  {
    name: 'AUXILIAR DE SERVICOS ESCOLARES',
    id: 345,
  },
  {
    name: 'AUXILIAR DE SERVICOS GERAIS',
    id: 346,
  },
  {
    name: 'AUXILIAR DE SUPERVISAO',
    id: 347,
  },
  {
    name: 'AUXILIAR DE TALHACAO',
    id: 348,
  },
  {
    name: 'AUXILIAR DE TECELAO',
    id: 349,
  },
  {
    name: 'AUXILIAR DE TINTURARIA',
    id: 350,
  },
  {
    name: 'AUXILIAR DE TOPOGRAFIA',
    id: 351,
  },
  {
    name: 'AUXILIAR DE TRAFEGO',
    id: 352,
  },
  {
    name: 'AUXILIAR DE TRANSPORTE',
    id: 353,
  },
  {
    name: 'AUXILIAR DE VENDAS',
    id: 354,
  },
  {
    name: 'AUXILIAR DE VIDRACEIRO',
    id: 355,
  },
  {
    name: 'AUXILIAR DENTISTA',
    id: 356,
  },
  {
    name: 'AUXILIAR DEPARTAMENTO PESSOAL',
    id: 357,
  },
  {
    name: 'AUXILIAR EDUCACIONAL',
    id: 358,
  },
  {
    name: 'AUXILIAR ELETRONICO',
    id: 359,
  },
  {
    name: 'AUXILIAR ESCOLAR',
    id: 360,
  },
  {
    name: 'AUXILIAR FINANCEIRO',
    id: 361,
  },
  {
    name: 'AUXILIAR FISCAL',
    id: 362,
  },
  {
    name: 'AUXILIAR GERAL',
    id: 363,
  },
  {
    name: 'AUXILIAR MECANICO',
    id: 364,
  },
  {
    name: 'AUXILIAR METALURGICO',
    id: 365,
  },
  {
    name: 'AUXILIAR ODONTOLOGICA',
    id: 366,
  },
  {
    name: 'AUXILIAR ODONTOLOGICO',
    id: 367,
  },
  {
    name: 'AUXILIAR OPERACIONAL',
    id: 368,
  },
  {
    name: 'AUXILIAR PEDAGOGICO',
    id: 369,
  },
  {
    name: 'AUXILIAR TECNICO',
    id: 370,
  },
  {
    name: 'AUXILIAR TECNICO DE RADIOLAGIA',
    id: 371,
  },
  {
    name: 'AUXILIAR TECNICO MANUTENCAO',
    id: 372,
  },
  {
    name: 'AUXILIAR TORNEIRO MECANICO',
    id: 373,
  },
  {
    name: 'AUXILIO DOENCA',
    id: 374,
  },
  {
    name: 'AUXLIAR DE LIMPEZA',
    id: 375,
  },
  {
    name: 'AVICULTOR',
    id: 376,
  },
  {
    name: 'AZULEJISTA',
    id: 377,
  },
  {
    name: 'BABA',
    id: 378,
  },
  {
    name: 'BALANCEIRO',
    id: 379,
  },
  {
    name: 'BALCONISTA',
    id: 380,
  },
  {
    name: 'BALCONISTA III',
    id: 381,
  },
  {
    name: 'BANCARIA',
    id: 382,
  },
  {
    name: 'BANCARIO',
    id: 383,
  },
  {
    name: 'BANHISTA',
    id: 384,
  },
  {
    name: 'BANHO E TOSA',
    id: 385,
  },
  {
    name: 'BARBEIRO',
    id: 386,
  },
  {
    name: 'BARMAM',
    id: 387,
  },
  {
    name: 'BARMAN',
    id: 388,
  },
  {
    name: 'BIBLIOTECARIA',
    id: 389,
  },
  {
    name: 'BIOLOGA',
    id: 390,
  },
  {
    name: 'BIOMEDICA',
    id: 391,
  },
  {
    name: 'BLOQUISTA',
    id: 392,
  },
  {
    name: 'BOBINADOR',
    id: 393,
  },
  {
    name: 'BOLSISTA',
    id: 394,
  },
  {
    name: 'BOMBEIRO',
    id: 395,
  },
  {
    name: 'BORDADEIRA',
    id: 396,
  },
  {
    name: 'BORDADOR',
    id: 397,
  },
  {
    name: 'BORRACHEIRO',
    id: 398,
  },
  {
    name: 'CABELEIREIRO',
    id: 399,
  },
  {
    name: 'CABELELEIRA',
    id: 400,
  },
  {
    name: 'CABELELEIRO (A)',
    id: 401,
  },
  {
    name: 'CABELEREIRA',
    id: 402,
  },
  {
    name: 'CABELEREIRO',
    id: 403,
  },
  {
    name: 'CABISTA DE TELECOMUNICACOES',
    id: 404,
  },
  {
    name: 'CABO',
    id: 405,
  },
  {
    name: 'CAIXA',
    id: 406,
  },
  {
    name: 'CAIXA DE BANCO',
    id: 407,
  },
  {
    name: 'CAIXA II',
    id: 408,
  },
  {
    name: 'CALCETEIRO',
    id: 409,
  },
  {
    name: 'CALDEIREIRO',
    id: 410,
  },
  {
    name: 'CALDEREIRO',
    id: 411,
  },
  {
    name: 'CALHEIRO',
    id: 412,
  },
  {
    name: 'CAMAREIRA',
    id: 413,
  },
  {
    name: 'CAMAREIRO (HOTEL)',
    id: 414,
  },
  {
    name: 'CAMINHONEIRO',
    id: 415,
  },
  {
    name: 'CANTOR',
    id: 416,
  },
  {
    name: 'CANTOR (A)',
    id: 417,
  },
  {
    name: 'CAPATAZ DE EXPLORAÇAO AGRÍCOLA',
    id: 418,
  },
  {
    name: 'CAPATAZ DE EXPLORAÇAO DE PECUÁRIA',
    id: 419,
  },
  {
    name: 'CARGO DE COMISSAO',
    id: 420,
  },
  {
    name: 'CARPINTEIRO',
    id: 421,
  },
  {
    name: 'CARPINTEIRO (OBRAS)',
    id: 422,
  },
  {
    name: 'CARREGADOR (ARMAZÉM)',
    id: 423,
  },
  {
    name: 'CARREGADOR (VEÍCULOS DE TRANSPORTES TERRESTRES)',
    id: 424,
  },
  {
    name: 'CARTAZISTA',
    id: 425,
  },
  {
    name: 'CARTEIRO',
    id: 426,
  },
  {
    name: 'CASEIRO',
    id: 427,
  },
  {
    name: 'CATADOR DE PAPELAO',
    id: 428,
  },
  {
    name: 'CERAMISTA',
    id: 429,
  },
  {
    name: 'CERIMONIAL',
    id: 430,
  },
  {
    name: 'CHACREIRO',
    id: 431,
  },
  {
    name: 'CHAPEADOR',
    id: 432,
  },
  {
    name: 'CHAPEIRO (A)',
    id: 433,
  },
  {
    name: 'CHAPISTA DE LANCHONETE',
    id: 434,
  },
  {
    name: 'CHAVEIRO',
    id: 435,
  },
  {
    name: 'CHEF DE COZINHA',
    id: 436,
  },
  {
    name: 'CHEFE',
    id: 437,
  },
  {
    name: 'CHEFE ADMINISTRATIVO',
    id: 438,
  },
  {
    name: 'CHEFE DE CAIXA',
    id: 439,
  },
  {
    name: 'CHEFE DE CONFEITARIA',
    id: 440,
  },
  {
    name: 'CHEFE DE COZINHA',
    id: 441,
  },
  {
    name: 'CHEFE DE DIVISAO',
    id: 442,
  },
  {
    name: 'CHEFE DE ESCRITÓRIO (SERVIÇOS GERAIS)',
    id: 443,
  },
  {
    name: 'CHEFE DE GABINETE',
    id: 444,
  },
  {
    name: 'CHEFE DE LOJA',
    id: 445,
  },
  {
    name: 'CHEFE DE MANUTENCAO',
    id: 446,
  },
  {
    name: 'CHEFE DE OFICINA',
    id: 447,
  },
  {
    name: 'CHEFE DE PRODUCAO',
    id: 448,
  },
  {
    name: 'CHEFE DE SETOR',
    id: 449,
  },
  {
    name: 'CHURRASQUEIRO',
    id: 450,
  },
  {
    name: 'CINEGRAFISTA',
    id: 451,
  },
  {
    name: 'CIRURGIAO DENTISTA',
    id: 452,
  },
  {
    name: 'CIRURGIAO-DENTISTA',
    id: 453,
  },
  {
    name: 'CLASSIFICADOR',
    id: 454,
  },
  {
    name: 'COBRADOR',
    id: 455,
  },
  {
    name: 'COBRADOR DE TRANSPORTE COLETIVO (EXCETO TREM)',
    id: 456,
  },
  {
    name: 'COBRADORA',
    id: 457,
  },
  {
    name: 'COBRANCA',
    id: 458,
  },
  {
    name: 'COLETADOR',
    id: 459,
  },
  {
    name: 'COLETOR',
    id: 460,
  },
  {
    name: 'COLHEDOR',
    id: 461,
  },
  {
    name: 'COLOCADOR',
    id: 462,
  },
  {
    name: 'COLOCADOR DE MARMORES',
    id: 463,
  },
  {
    name: 'COLORISTA',
    id: 464,
  },
  {
    name: 'COMERCIANTE',
    id: 465,
  },
  {
    name: 'COMERCIANTE ATACADISTA',
    id: 466,
  },
  {
    name: 'COMERCIANTE VAREJISTA',
    id: 467,
  },
  {
    name: 'COMERCIARIA',
    id: 468,
  },
  {
    name: 'COMERCIARIO',
    id: 469,
  },
  {
    name: 'COMPRADOR (COMÉRCIO ATACADISTA E VAREJISTA)',
    id: 470,
  },
  {
    name: 'COMPRADOR(A)',
    id: 471,
  },
  {
    name: 'COMPRADORA',
    id: 472,
  },
  {
    name: 'COMUNICACAO VISUAL',
    id: 473,
  },
  {
    name: 'CONDUTOR DE MAQUINA',
    id: 474,
  },
  {
    name: 'CONDUTOR DE MOTO ENTRGA',
    id: 475,
  },
  {
    name: 'CONFEITEIRA',
    id: 476,
  },
  {
    name: 'CONFEITEIRO',
    id: 477,
  },
  {
    name: 'CONFEITEIRO (A)',
    id: 478,
  },
  {
    name: 'CONFERENTE',
    id: 479,
  },
  {
    name: 'CONFERENTE DE CARGAS',
    id: 480,
  },
  {
    name: 'CONFERENTE DE EXPEDICAO',
    id: 481,
  },
  {
    name: 'CONFERENTE DE LOGISTICA',
    id: 482,
  },
  {
    name: 'CONFERENTE DE MATERIAL',
    id: 483,
  },
  {
    name: 'CONSELHEIRO TUTELAR',
    id: 484,
  },
  {
    name: 'CONSERTADOR DE CALCADOS',
    id: 485,
  },
  {
    name: 'CONSERTADOR DE REFRIGERACAO',
    id: 486,
  },
  {
    name: 'CONSTRUTOR',
    id: 487,
  },
  {
    name: 'CONSTRUTOR CIVIL',
    id: 488,
  },
  {
    name: 'CONSTRUTOR DE OBRAS',
    id: 489,
  },
  {
    name: 'CONSULTOR',
    id: 490,
  },
  {
    name: 'CONSULTOR COMERCIAL',
    id: 491,
  },
  {
    name: 'CONSULTOR DE CREDITO',
    id: 492,
  },
  {
    name: 'CONSULTOR DE NEGOCIOS',
    id: 493,
  },
  {
    name: 'CONSULTOR DE RELACIONAMENTO COMERCIAL',
    id: 494,
  },
  {
    name: 'CONSULTOR DE VENDAS',
    id: 495,
  },
  {
    name: 'CONSULTOR TECNICO',
    id: 496,
  },
  {
    name: 'CONSULTORA',
    id: 497,
  },
  {
    name: 'CONSULTORA DE BELEZA',
    id: 498,
  },
  {
    name: 'CONTABILISTA',
    id: 499,
  },
  {
    name: 'CONTADOR',
    id: 500,
  },
  {
    name: 'CONTADORA (O)',
    id: 501,
  },
  {
    name: 'CONTINUO',
    id: 502,
  },
  {
    name: 'CONTRA MESTRE',
    id: 503,
  },
  {
    name: 'CONTROLADOR DE EMBARQUE',
    id: 504,
  },
  {
    name: 'CONTROLADOR DE ESTOQUE',
    id: 505,
  },
  {
    name: 'CONTROLADOR DE OPERACOES',
    id: 506,
  },
  {
    name: 'CONTROLADOR DE PRODUCAO',
    id: 507,
  },
  {
    name: 'CONTROLADOR DE QUALIDADE',
    id: 508,
  },
  {
    name: 'CONTROLADOR DE TRAFEGO',
    id: 509,
  },
  {
    name: 'CONTROLADOR E/S',
    id: 510,
  },
  {
    name: 'CONTROLE DE QUALIDADE',
    id: 511,
  },
  {
    name: 'COORDENADOR',
    id: 512,
  },
  {
    name: 'COORDENADOR (A) DE VENDAS',
    id: 513,
  },
  {
    name: 'COORDENADOR ADMINISTRATIVO',
    id: 514,
  },
  {
    name: 'COORDENADOR DE ENSINO',
    id: 515,
  },
  {
    name: 'COORDENADOR DE EQUIPE',
    id: 516,
  },
  {
    name: 'COORDENADOR DE EVENTOS',
    id: 517,
  },
  {
    name: 'COORDENADOR DE LOGISTICA',
    id: 518,
  },
  {
    name: 'COORDENADOR(A)',
    id: 519,
  },
  {
    name: 'COORDENADORA',
    id: 520,
  },
  {
    name: 'COORDENADORA PEDAGOGICA',
    id: 521,
  },
  {
    name: 'COPEIRA',
    id: 522,
  },
  {
    name: 'COPEIRO',
    id: 523,
  },
  {
    name: 'COPEIRO (HOSPITAL)',
    id: 524,
  },
  {
    name: 'COPERA',
    id: 525,
  },
  {
    name: 'CORDENADOR OPERACIONAL',
    id: 526,
  },
  {
    name: 'CORDENADORA CONTABIL',
    id: 527,
  },
  {
    name: 'CORRETOR DE IMOVEIS',
    id: 528,
  },
  {
    name: 'CORRETOR DE SEGUROS',
    id: 529,
  },
  {
    name: 'CORRETOR(A)',
    id: 530,
  },
  {
    name: 'CORRETORA DE IMOVEIS',
    id: 531,
  },
  {
    name: 'CORRETORA DE SEGUROS',
    id: 532,
  },
  {
    name: 'CORTADEIRA',
    id: 533,
  },
  {
    name: 'CORTADOR',
    id: 534,
  },
  {
    name: 'CORTE',
    id: 535,
  },
  {
    name: 'COSTURA',
    id: 536,
  },
  {
    name: 'COSTUREIRA',
    id: 537,
  },
  {
    name: 'COSTUREIRA AUTONOMA',
    id: 538,
  },
  {
    name: 'COSTUREIRA E ARTESA',
    id: 539,
  },
  {
    name: 'COSTUREIRO',
    id: 540,
  },
  {
    name: 'COVEIRO',
    id: 541,
  },
  {
    name: 'COZINHADOR',
    id: 542,
  },
  {
    name: 'COZINHEIRA',
    id: 543,
  },
  {
    name: 'COZINHEIRO',
    id: 544,
  },
  {
    name: 'COZINHEIRO CHEFE',
    id: 545,
  },
  {
    name: 'CREDIARISTA',
    id: 546,
  },
  {
    name: 'CUIDADOR DE IDOSO',
    id: 547,
  },
  {
    name: 'DATILOGRAFO',
    id: 548,
  },
  {
    name: 'DECORADOR DE INTERIORES',
    id: 549,
  },
  {
    name: 'DECORADOR(A)',
    id: 550,
  },
  {
    name: 'DENTISTA',
    id: 551,
  },
  {
    name: 'DEPARTAMENTO DE FATURAMENTO',
    id: 552,
  },
  {
    name: 'DEPARTAMENTO PESSOAL',
    id: 553,
  },
  {
    name: 'DEPILADORA',
    id: 554,
  },
  {
    name: 'DEPOSITO',
    id: 555,
  },
  {
    name: 'DESCARREGADOR',
    id: 556,
  },
  {
    name: 'DESEMPREGADO',
    id: 557,
  },
  {
    name: 'DESENHISTA',
    id: 558,
  },
  {
    name: 'DESENHISTA INDUSTRIAL (DESIGNER)',
    id: 559,
  },
  {
    name: 'DESENHISTA PROJETISTA',
    id: 560,
  },
  {
    name: 'DESENHISTA TECNICO',
    id: 561,
  },
  {
    name: 'DESIGNER',
    id: 562,
  },
  {
    name: 'DESOSSADOR',
    id: 563,
  },
  {
    name: 'DESPACHANTE DO DETRAN',
    id: 564,
  },
  {
    name: 'DETETIZADOR',
    id: 565,
  },
  {
    name: 'DIARISTA',
    id: 566,
  },
  {
    name: 'DIGITADOR (A)',
    id: 567,
  },
  {
    name: 'DIGITADORA',
    id: 568,
  },
  {
    name: 'DIRETOR',
    id: 569,
  },
  {
    name: 'DIRETOR ADMINISTRATIVO',
    id: 570,
  },
  {
    name: 'DIRETOR COMERCIAL',
    id: 571,
  },
  {
    name: 'DIRETOR DE DEPARTAMENTO',
    id: 572,
  },
  {
    name: 'DIRETORA',
    id: 573,
  },
  {
    name: 'DISTRIBUIDOR',
    id: 574,
  },
  {
    name: 'DISTRIBUIDORA DE SERVICOS',
    id: 575,
  },
  {
    name: 'DIVULGADOR',
    id: 576,
  },
  {
    name: 'DO LAR',
    id: 577,
  },
  {
    name: 'DOBRADEIRA',
    id: 578,
  },
  {
    name: 'DOCEIRA',
    id: 579,
  },
  {
    name: 'DOMADOR',
    id: 580,
  },
  {
    name: 'DOMESTICA',
    id: 581,
  },
  {
    name: 'DOMESTICA E DIARISTA',
    id: 582,
  },
  {
    name: 'EDITOR DE IMAGENS',
    id: 583,
  },
  {
    name: 'EDITOR DE VIDEO',
    id: 584,
  },
  {
    name: 'EDUCADOR(A)',
    id: 585,
  },
  {
    name: 'EDUCADORA',
    id: 586,
  },
  {
    name: 'EDUCADORA PEDAGOGICA',
    id: 587,
  },
  {
    name: 'EDUCADORA SOCIAL',
    id: 588,
  },
  {
    name: 'ELETRECISTA',
    id: 589,
  },
  {
    name: 'ELETRECISTA INDUSTRIAL',
    id: 590,
  },
  {
    name: 'ELETRICISTA',
    id: 591,
  },
  {
    name: 'ELETRICISTA AUTOMOVEIS',
    id: 592,
  },
  {
    name: 'ELETRICISTA DE AUTOMOVEIS',
    id: 593,
  },
  {
    name: 'ELETRICISTA DE INSTALAÇOES',
    id: 594,
  },
  {
    name: 'ELETRICISTA DE MANUTENÇAO',
    id: 595,
  },
  {
    name: 'ELETROMECANICO',
    id: 596,
  },
  {
    name: 'ELETROTECNICO',
    id: 597,
  },
  {
    name: 'EMBALADOR',
    id: 598,
  },
  {
    name: 'EMBALADOR (A)',
    id: 599,
  },
  {
    name: 'EMISSOR DE PASSAGENS',
    id: 600,
  },
  {
    name: 'EMPACOTADOR(A)',
    id: 601,
  },
  {
    name: 'EMPACOTADORA',
    id: 602,
  },
  {
    name: 'EMPILHADOR',
    id: 603,
  },
  {
    name: 'EMPREGADA DOMESTICA',
    id: 604,
  },
  {
    name: 'EMPREGADO DOMÉSTICO',
    id: 605,
  },
  {
    name: 'EMPREITEIRO',
    id: 606,
  },
  {
    name: 'EMPREITERO DE OBRAS',
    id: 607,
  },
  {
    name: 'EMPRESARIA',
    id: 608,
  },
  {
    name: 'EMPRESARIO (A)',
    id: 609,
  },
  {
    name: 'ENCANADOR',
    id: 610,
  },
  {
    name: 'ENCAREGADA DE PRODUCAO',
    id: 611,
  },
  {
    name: 'ENCAREGADO DE DEPOSITO',
    id: 612,
  },
  {
    name: 'ENCAREGADO DE PRODUCAO',
    id: 613,
  },
  {
    name: 'ENCAREGADO DE SERVICOS',
    id: 614,
  },
  {
    name: 'ENCAREGADO DO ACOUGUE',
    id: 615,
  },
  {
    name: 'ENCARREAGADO GERAL',
    id: 616,
  },
  {
    name: 'ENCARREGADA DE COMPRAS',
    id: 617,
  },
  {
    name: 'ENCARREGADA DE DEPARTAMENTO FISCAL',
    id: 618,
  },
  {
    name: 'ENCARREGADA DE OBRAS',
    id: 619,
  },
  {
    name: 'ENCARREGADA DE PRODUCAO',
    id: 620,
  },
  {
    name: 'ENCARREGADA DE VENDAS',
    id: 621,
  },
  {
    name: 'ENCARREGADA DE ZELADORAS',
    id: 622,
  },
  {
    name: 'ENCARREGADA(O) DE SERVICOS GERAIS',
    id: 623,
  },
  {
    name: 'ENCARREGADO (A)',
    id: 624,
  },
  {
    name: 'ENCARREGADO ADMINISTRATIVO',
    id: 625,
  },
  {
    name: 'ENCARREGADO DE ALMOXARIFADO',
    id: 626,
  },
  {
    name: 'ENCARREGADO DE ASSISTENCIA TECNICA',
    id: 627,
  },
  {
    name: 'ENCARREGADO DE DEPARTAMENTO PESSOAL',
    id: 628,
  },
  {
    name: 'ENCARREGADO DE EMBALAGEM',
    id: 629,
  },
  {
    name: 'ENCARREGADO DE EXPEDICAO',
    id: 630,
  },
  {
    name: 'ENCARREGADO DE LOJA',
    id: 631,
  },
  {
    name: 'ENCARREGADO DE MANUTENCAO',
    id: 632,
  },
  {
    name: 'ENCARREGADO DE OBRAS',
    id: 633,
  },
  {
    name: 'ENCARREGADO DE PRODUCAO',
    id: 634,
  },
  {
    name: 'ENCARREGADO DE SETOR',
    id: 635,
  },
  {
    name: 'ENCARREGADO(A)',
    id: 636,
  },
  {
    name: 'ENCOSTADO',
    id: 637,
  },
  {
    name: 'ENFERMEIRA',
    id: 638,
  },
  {
    name: 'ENFERMEIRO',
    id: 639,
  },
  {
    name: 'ENGENHEIRA',
    id: 640,
  },
  {
    name: 'ENGENHEIRO',
    id: 641,
  },
  {
    name: 'ENGENHEIRO AGRONOMO',
    id: 642,
  },
  {
    name: 'ENGENHEIRO CIVIL',
    id: 643,
  },
  {
    name: 'ENGENHEIRO ELETRICISTA',
    id: 644,
  },
  {
    name: 'ENGENHEIRO MECANICO',
    id: 645,
  },
  {
    name: 'ENTREGADOR',
    id: 646,
  },
  {
    name: 'ESCREVENTE',
    id: 647,
  },
  {
    name: 'ESCRITURARIA',
    id: 648,
  },
  {
    name: 'ESCRITURARIO',
    id: 649,
  },
  {
    name: 'ESCRIVAO',
    id: 650,
  },
  {
    name: 'ESMERILHADOR',
    id: 651,
  },
  {
    name: 'ESTAGIARIA',
    id: 652,
  },
  {
    name: 'ESTAGIARIO',
    id: 653,
  },
  {
    name: 'ESTAMPADOR',
    id: 654,
  },
  {
    name: 'ESTAMPADOR DE TECIDOS',
    id: 655,
  },
  {
    name: 'ESTETICISTA',
    id: 656,
  },
  {
    name: 'ESTETICISTA DE ANIMAIS',
    id: 657,
  },
  {
    name: 'ESTILISTA',
    id: 658,
  },
  {
    name: 'ESTIVADOR',
    id: 659,
  },
  {
    name: 'ESTOFADOR DE MÓVEIS',
    id: 660,
  },
  {
    name: 'ESTOFADOR DE VEÍCULOS',
    id: 661,
  },
  {
    name: 'ESTOQUISTA',
    id: 662,
  },
  {
    name: 'ESTUDANTE',
    id: 663,
  },
  {
    name: 'ESTUDANTE UNIVERSITARIO',
    id: 664,
  },
  {
    name: 'EXECUTIVO DE VENDAS',
    id: 665,
  },
  {
    name: 'EXPEDIDOR DE MATERIAIS',
    id: 666,
  },
  {
    name: 'EXPEDIDOR(A)',
    id: 667,
  },
  {
    name: 'EXPEDITOR DE QUALIDADE',
    id: 668,
  },
  {
    name: 'EXTRUSOR',
    id: 669,
  },
  {
    name: 'FACCIONISTA',
    id: 670,
  },
  {
    name: 'FACILITADOR',
    id: 671,
  },
  {
    name: 'FARMACEUTICA',
    id: 672,
  },
  {
    name: 'FARMACEUTICO',
    id: 673,
  },
  {
    name: 'FARMACEUTICO (A)',
    id: 674,
  },
  {
    name: 'FATURISTA',
    id: 675,
  },
  {
    name: 'FAXINEIRA',
    id: 676,
  },
  {
    name: 'FAXINEIRO',
    id: 677,
  },
  {
    name: 'FEIRANTE',
    id: 678,
  },
  {
    name: 'FERRAMENTEIRO',
    id: 679,
  },
  {
    name: 'FERREIRO',
    id: 680,
  },
  {
    name: 'FINANCEIRO',
    id: 681,
  },
  {
    name: 'FISCAL',
    id: 682,
  },
  {
    name: 'FISCAL DE CAIXA',
    id: 683,
  },
  {
    name: 'FISCAL DE LOJA',
    id: 684,
  },
  {
    name: 'FISCAL DE OBRAS',
    id: 685,
  },
  {
    name: 'FISCAL DE SEGURANCA',
    id: 686,
  },
  {
    name: 'FISIOTERAPEUTA',
    id: 687,
  },
  {
    name: 'FLORISTA',
    id: 688,
  },
  {
    name: 'FOLGUISTA',
    id: 689,
  },
  {
    name: 'FONOAUDIOLOGA',
    id: 690,
  },
  {
    name: 'FORNEIRO',
    id: 691,
  },
  {
    name: 'FOTOGRAFO',
    id: 692,
  },
  {
    name: 'FOTOGRAFO PUBLICITÁRIO',
    id: 693,
  },
  {
    name: 'FREE LANCE',
    id: 694,
  },
  {
    name: 'FRENTE DE CAIXA',
    id: 695,
  },
  {
    name: 'FRENTISTA',
    id: 696,
  },
  {
    name: 'FRESADOR (FRESADORA UNIVERSAL)',
    id: 697,
  },
  {
    name: 'FRESADOR CNC',
    id: 698,
  },
  {
    name: 'FRETISTA',
    id: 699,
  },
  {
    name: 'FUNCIONAQRIA PUBLICA',
    id: 700,
  },
  {
    name: 'FUNCIONARIA PUBLICA',
    id: 701,
  },
  {
    name: 'FUNCIONARIO (A) PUBLICO',
    id: 702,
  },
  {
    name: 'FUNCIONARIO PUBLICO',
    id: 703,
  },
  {
    name: 'FUNDIDOR DE METAIS',
    id: 704,
  },
  {
    name: 'FUNILEIRO',
    id: 705,
  },
  {
    name: 'GALVANIZADOR',
    id: 706,
  },
  {
    name: 'GARAGISTA',
    id: 707,
  },
  {
    name: 'GARCOM',
    id: 708,
  },
  {
    name: 'GARCON',
    id: 709,
  },
  {
    name: 'GARCONETE',
    id: 710,
  },
  {
    name: 'GARI',
    id: 711,
  },
  {
    name: 'GARIMPEIRO',
    id: 712,
  },
  {
    name: 'GERENCIADOR',
    id: 713,
  },
  {
    name: 'GERENTE',
    id: 714,
  },
  {
    name: 'GERENTE ADJUNTO',
    id: 715,
  },
  {
    name: 'GERENTE ADMINISTRATIVO',
    id: 716,
  },
  {
    name: 'GERENTE COMERCIAL',
    id: 717,
  },
  {
    name: 'GERENTE DE ADIMINISTRACAO E VENDAS',
    id: 718,
  },
  {
    name: 'GERENTE DE AREA',
    id: 719,
  },
  {
    name: 'GERENTE DE COMPRAS',
    id: 720,
  },
  {
    name: 'GERENTE DE LOJA',
    id: 721,
  },
  {
    name: 'GERENTE DE MANUTENCAO',
    id: 722,
  },
  {
    name: 'GERENTE DE OPERAÇAO',
    id: 723,
  },
  {
    name: 'GERENTE DE PRODUCAO',
    id: 724,
  },
  {
    name: 'GERENTE DE PRODUÇAO',
    id: 725,
  },
  {
    name: 'GERENTE DE RESTAURANTE',
    id: 726,
  },
  {
    name: 'GERENTE DE RH',
    id: 727,
  },
  {
    name: 'GERENTE DE VENDAS',
    id: 728,
  },
  {
    name: 'GERENTE FINANCEIRO',
    id: 729,
  },
  {
    name: 'GERENTE GERAL',
    id: 730,
  },
  {
    name: 'GERENTE OPERACIONAL',
    id: 731,
  },
  {
    name: 'GESSEIRO',
    id: 732,
  },
  {
    name: 'GESTOR DE CONTRATOS',
    id: 733,
  },
  {
    name: 'GESTOR DE ESTOQUE',
    id: 734,
  },
  {
    name: 'GESTOR DE NOGOCIOS',
    id: 735,
  },
  {
    name: 'GESTOR DE SERVICOS GERAIS',
    id: 736,
  },
  {
    name: 'GOVERNANTA (HOTELARIA)',
    id: 737,
  },
  {
    name: 'GOVERNANTA(CASA DE FAMILIA)',
    id: 738,
  },
  {
    name: 'GRAFICO',
    id: 739,
  },
  {
    name: 'GUARDA DE SEGURANCA',
    id: 740,
  },
  {
    name: 'GUARDA MUNICIPAL',
    id: 741,
  },
  {
    name: 'GUINCHEIRO',
    id: 742,
  },
  {
    name: 'HIGIENIZACAO',
    id: 743,
  },
  {
    name: 'HORTICULTOR',
    id: 744,
  },
  {
    name: 'IMPERMEABILIZADOR',
    id: 745,
  },
  {
    name: 'IMPRESSOR',
    id: 746,
  },
  {
    name: 'IMPRESSOR (SERIGRAFIA)',
    id: 747,
  },
  {
    name: 'IMPRESSOR FLEXOGRAFICO',
    id: 748,
  },
  {
    name: 'INSPETOR',
    id: 749,
  },
  {
    name: 'INSPETOR DE ALUNOS',
    id: 750,
  },
  {
    name: 'INSPETOR DE QUALIDADE',
    id: 751,
  },
  {
    name: 'INSPETOR DE SEGURANCA',
    id: 752,
  },
  {
    name: 'INSPETOR DE VIGILANCIA PATRIMONIAL',
    id: 753,
  },
  {
    name: 'INSPETORA',
    id: 754,
  },
  {
    name: 'INSTALADOR',
    id: 755,
  },
  {
    name: 'INSTALADOR ACESSORIOS',
    id: 756,
  },
  {
    name: 'INSTALADOR DE ACESSORIOS',
    id: 757,
  },
  {
    name: 'INSTALADOR DE ANTENAS',
    id: 758,
  },
  {
    name: 'INSTALADOR DE AR CONDICIONADO',
    id: 759,
  },
  {
    name: 'INSTALADOR DE SOM AUTOMOTIVO',
    id: 760,
  },
  {
    name: 'INSTALADOR DE TELEFONE',
    id: 761,
  },
  {
    name: 'INSTALADOR HIDRAULICO',
    id: 762,
  },
  {
    name: 'INSTRUMENTADOR CIRURGICO',
    id: 763,
  },
  {
    name: 'INSTRUTOR',
    id: 764,
  },
  {
    name: 'INSTRUTOR (A)',
    id: 765,
  },
  {
    name: 'INSTRUTOR DE DIRECAO',
    id: 766,
  },
  {
    name: 'INSTRUTOR DE ENSINO',
    id: 767,
  },
  {
    name: 'INSTRUTOR DE INFORMATICA',
    id: 768,
  },
  {
    name: 'INSTRUTOR DE TRANSITO',
    id: 769,
  },
  {
    name: 'INSTRUTORA',
    id: 770,
  },
  {
    name: 'INVESTIGADOR DE POLICIA',
    id: 771,
  },
  {
    name: 'JARDINEIRO',
    id: 772,
  },
  {
    name: 'JOGADOR DE FUTEBOL',
    id: 773,
  },
  {
    name: 'JORNALISTA',
    id: 774,
  },
  {
    name: 'LABORATORISTA',
    id: 775,
  },
  {
    name: 'LACTARISTA',
    id: 776,
  },
  {
    name: 'LAMINADOR',
    id: 777,
  },
  {
    name: 'LANCHEIRO',
    id: 778,
  },
  {
    name: 'LATOEIRO',
    id: 779,
  },
  {
    name: 'LATOEIRO-PINTOR',
    id: 780,
  },
  {
    name: 'LAVADEIRA',
    id: 781,
  },
  {
    name: 'LAVADEIRA (O)',
    id: 782,
  },
  {
    name: 'LAVADOR DE MAQUINAS',
    id: 783,
  },
  {
    name: 'LAVADOR DE VEICULOS',
    id: 784,
  },
  {
    name: 'LAVADOR DE VEÍCULOS',
    id: 785,
  },
  {
    name: 'LAVADOR(A)',
    id: 786,
  },
  {
    name: 'LAVRADOR',
    id: 787,
  },
  {
    name: 'LEITURISTA',
    id: 788,
  },
  {
    name: 'LIDER',
    id: 789,
  },
  {
    name: 'LIDER DE ALMOXARIFADO',
    id: 790,
  },
  {
    name: 'LIDER DE APOIO LOGISTICO',
    id: 791,
  },
  {
    name: 'LIDER DE CELULA',
    id: 792,
  },
  {
    name: 'LIDER DE OPERACAO',
    id: 793,
  },
  {
    name: 'LIDER DE PADARIA',
    id: 794,
  },
  {
    name: 'LIDER DE PRODUCAO',
    id: 795,
  },
  {
    name: 'LIDER DE SECAO',
    id: 796,
  },
  {
    name: 'LIDER DE SETOR',
    id: 797,
  },
  {
    name: 'LIDER DE VENDAS',
    id: 798,
  },
  {
    name: 'LIMPADOR A SECO',
    id: 799,
  },
  {
    name: 'LIMPADOR DE PISCINAS',
    id: 800,
  },
  {
    name: 'LIXADOR DE MOVEIS',
    id: 801,
  },
  {
    name: 'LOCUTOR',
    id: 802,
  },
  {
    name: 'LOGISTICA',
    id: 803,
  },
  {
    name: 'LUBRIFICADOR',
    id: 804,
  },
  {
    name: 'LUBRIFICADOR DE VEÍCULOS AUTOMOTORES (EXCETO EMBARCAÇOES)',
    id: 805,
  },
  {
    name: 'MADEREIRO',
    id: 806,
  },
  {
    name: 'MAGAREFE',
    id: 807,
  },
  {
    name: 'MANICURE',
    id: 808,
  },
  {
    name: 'MANICURE E PODOLOGA',
    id: 809,
  },
  {
    name: 'MANICURI',
    id: 810,
  },
  {
    name: 'MANIPULADORA',
    id: 811,
  },
  {
    name: 'MANOBRISTA',
    id: 812,
  },
  {
    name: 'MANTENEDOR I',
    id: 813,
  },
  {
    name: 'MANUAL',
    id: 814,
  },
  {
    name: 'MANUTENCAO',
    id: 815,
  },
  {
    name: 'MANUTENCAO PREDIAL',
    id: 816,
  },
  {
    name: 'MAQUIADOR',
    id: 817,
  },
  {
    name: 'MAQUINISTA DE TREM',
    id: 818,
  },
  {
    name: 'MARCENEIRO',
    id: 819,
  },
  {
    name: 'MARCINEIRO',
    id: 820,
  },
  {
    name: 'MARINHEIRO',
    id: 821,
  },
  {
    name: 'MARKETING',
    id: 822,
  },
  {
    name: 'MARMORISTA',
    id: 823,
  },
  {
    name: 'MASSAGISTA',
    id: 824,
  },
  {
    name: 'MASSEIRO (MASSAS ALIMENTÍCIAS)',
    id: 825,
  },
  {
    name: 'MASSOTERAPEUTA',
    id: 826,
  },
  {
    name: 'MECANICO',
    id: 827,
  },
  {
    name: 'MECANICO DE MANUTENCAO',
    id: 828,
  },
  {
    name: 'MECANICO DE MANUTENCAO DE AUTOMÓVEIS',
    id: 829,
  },
  {
    name: 'MECANICO DE MANUTENCAO DE MOTOCICLETAS',
    id: 830,
  },
  {
    name: 'MECANICO DE MANUTENÇAO DE BICICLETAS E VEÍCULOS SIMILARES',
    id: 831,
  },
  {
    name: 'MECANICO DE MANUTENÇAO DE MÁQUINAS',
    id: 832,
  },
  {
    name: 'MECANICO DE REFRIGERACAO',
    id: 833,
  },
  {
    name: 'MECANICO GERAL',
    id: 834,
  },
  {
    name: 'MECANICO INDUSTRIAL',
    id: 835,
  },
  {
    name: 'MECANICO MONTADOR LIDER',
    id: 836,
  },
  {
    name: 'MEDICA',
    id: 837,
  },
  {
    name: 'MEDICA VETERINARIA',
    id: 838,
  },
  {
    name: 'MEDICO',
    id: 839,
  },
  {
    name: 'MEDICO VETERINARIO',
    id: 840,
  },
  {
    name: 'MEIO OFICIAL',
    id: 841,
  },
  {
    name: 'MEIO OFICIAL DE PEDREIRO',
    id: 842,
  },
  {
    name: 'MEIO OFICIAL MARCENEIRO',
    id: 843,
  },
  {
    name: 'MENOR APRENDIZ',
    id: 844,
  },
  {
    name: 'MENSAGEIRO',
    id: 845,
  },
  {
    name: 'MERENDEIRA',
    id: 846,
  },
  {
    name: 'MESTRE (CONSTRUÇAO CIVIL)',
    id: 847,
  },
  {
    name: 'MESTRE DE OBRAS',
    id: 848,
  },
  {
    name: 'METALURGICA',
    id: 849,
  },
  {
    name: 'METALURGICO',
    id: 850,
  },
  {
    name: 'MICRO EMPRESARIO',
    id: 851,
  },
  {
    name: 'MICROEMPRESARIA',
    id: 852,
  },
  {
    name: 'MICROEMPRESARIO',
    id: 853,
  },
  {
    name: 'MILITAR',
    id: 854,
  },
  {
    name: 'MILITAR PATENTE',
    id: 855,
  },
  {
    name: 'MINISTRO DE CULTO RELIGIOSO',
    id: 856,
  },
  {
    name: 'MISSIONARIO',
    id: 857,
  },
  {
    name: 'MODELISTA DE ROUPAS',
    id: 858,
  },
  {
    name: 'MODELO DE MODAS',
    id: 859,
  },
  {
    name: 'MOLDADOR',
    id: 860,
  },
  {
    name: 'MOLEIRO',
    id: 861,
  },
  {
    name: 'MONITOR(A)',
    id: 862,
  },
  {
    name: 'MONITORA',
    id: 863,
  },
  {
    name: 'MONITORADOR',
    id: 864,
  },
  {
    name: 'MONTADOR',
    id: 865,
  },
  {
    name: 'MONTADOR AUTOMOTIVO',
    id: 866,
  },
  {
    name: 'MONTADOR DE ANDAIMES (EDIFICAÇOES)',
    id: 867,
  },
  {
    name: 'MONTADOR DE EQUIPAMENTOS ELÉTRICOS',
    id: 868,
  },
  {
    name: 'MONTADOR DE ESTRUTURAS METÁLICAS',
    id: 869,
  },
  {
    name: 'MONTADOR DE MOVEIS',
    id: 870,
  },
  {
    name: 'MONTADOR DE MÁQUINAS',
    id: 871,
  },
  {
    name: 'MONTADOR DE PRODUCAO',
    id: 872,
  },
  {
    name: 'MONTADORA',
    id: 873,
  },
  {
    name: 'MONTAGEM',
    id: 874,
  },
  {
    name: 'MOTO BOY',
    id: 875,
  },
  {
    name: 'MOTO TAXI',
    id: 876,
  },
  {
    name: 'MOTOBOY',
    id: 877,
  },
  {
    name: 'MOTOCICLISTA (TRANSPORTE DE MERCADORIAS)',
    id: 878,
  },
  {
    name: 'MOTOQUEIRO',
    id: 879,
  },
  {
    name: 'MOTORISTA',
    id: 880,
  },
  {
    name: 'MOTORISTA AUTONOMA',
    id: 881,
  },
  {
    name: 'MOTORISTA CARRETEIRO',
    id: 882,
  },
  {
    name: 'MOTORISTA DE CAMINHAO',
    id: 883,
  },
  {
    name: 'MOTORISTA DE CARRO DE PASSEIO',
    id: 884,
  },
  {
    name: 'MOTORISTA DE ENTREGA',
    id: 885,
  },
  {
    name: 'MOTORISTA DE FURGAO OU VEÍCULO SIMILAR',
    id: 886,
  },
  {
    name: 'MOTORISTA DE ONIBUS FEDERAIS',
    id: 887,
  },
  {
    name: 'MOTORISTA DE TÁXI',
    id: 888,
  },
  {
    name: 'MOTORISTA DE ÔNIBUS',
    id: 889,
  },
  {
    name: 'MOTORISTA III',
    id: 890,
  },
  {
    name: 'MOTORISTA OPERADOR',
    id: 891,
  },
  {
    name: 'MOTORISTA PARTICULAR',
    id: 892,
  },
  {
    name: 'MOVIMENTADOR DE PRODUTO FIACAO',
    id: 893,
  },
  {
    name: 'MUSICO',
    id: 894,
  },
  {
    name: 'MUSICO DJ',
    id: 895,
  },
  {
    name: 'NEGOCIADOR INTERNO',
    id: 896,
  },
  {
    name: 'NUTRICIONISTA',
    id: 897,
  },
  {
    name: 'OFFICE BOY',
    id: 898,
  },
  {
    name: 'OFFICE-BOY',
    id: 899,
  },
  {
    name: 'OFICE BOY',
    id: 900,
  },
  {
    name: 'OFICIAL ADMINISTRATIVO',
    id: 901,
  },
  {
    name: 'OFICIAL DE ACABAMENTO',
    id: 902,
  },
  {
    name: 'OFICIAL DE JUSTICA',
    id: 903,
  },
  {
    name: 'OFICIAL DE LIMPEZA JR',
    id: 904,
  },
  {
    name: 'OFICIAL DE MANUTENCAO',
    id: 905,
  },
  {
    name: 'OFICIAL DE PRODUCAO I',
    id: 906,
  },
  {
    name: 'OLEIRO (FABRICAÇAO DE TELHAS)',
    id: 907,
  },
  {
    name: 'OLEIRO (FABRICAÇAO DE TIJOLOS)',
    id: 908,
  },
  {
    name: 'OPERADOR',
    id: 909,
  },
  {
    name: 'OPERADOR (A) DE LOCACAO',
    id: 910,
  },
  {
    name: 'OPERADOR CENTRAL DE COMUNICACAO',
    id: 911,
  },
  {
    name: 'OPERADOR COMERCIAL',
    id: 912,
  },
  {
    name: 'OPERADOR DE ACABAMENTO (INDÚSTRIA GRÁFICA)',
    id: 913,
  },
  {
    name: 'OPERADOR DE ACABAMENTO DE PEÇAS FUNDIDAS',
    id: 914,
  },
  {
    name: 'OPERADOR DE BETONEIRA',
    id: 915,
  },
  {
    name: 'OPERADOR DE CAIXA',
    id: 916,
  },
  {
    name: 'OPERADOR DE CALANDRA',
    id: 917,
  },
  {
    name: 'OPERADOR DE CALDEIRA',
    id: 918,
  },
  {
    name: 'OPERADOR DE CAMERA',
    id: 919,
  },
  {
    name: 'OPERADOR DE CARGA',
    id: 920,
  },
  {
    name: 'OPERADOR DE CARREGADEIRA',
    id: 921,
  },
  {
    name: 'OPERADOR DE CNC',
    id: 922,
  },
  {
    name: 'OPERADOR DE COBRANÇAS',
    id: 923,
  },
  {
    name: 'OPERADOR DE COLHEDEIRA',
    id: 924,
  },
  {
    name: 'OPERADOR DE CORTE E SOLDA',
    id: 925,
  },
  {
    name: 'OPERADOR DE CORTE E VINCO',
    id: 926,
  },
  {
    name: 'OPERADOR DE EMPILHADEIRA',
    id: 927,
  },
  {
    name: 'OPERADOR DE ESCAVADEIRA',
    id: 928,
  },
  {
    name: 'OPERADOR DE ESTACAO DE AGUA E ESGOTO',
    id: 929,
  },
  {
    name: 'OPERADOR DE ESTAMPARIA I',
    id: 930,
  },
  {
    name: 'OPERADOR DE ETA',
    id: 931,
  },
  {
    name: 'OPERADOR DE EXTRUSORA',
    id: 932,
  },
  {
    name: 'OPERADOR DE FABRICACAO',
    id: 933,
  },
  {
    name: 'OPERADOR DE FIACAO',
    id: 934,
  },
  {
    name: 'OPERADOR DE FORNOS',
    id: 935,
  },
  {
    name: 'OPERADOR DE GUINDASTE (MÓVEL)',
    id: 936,
  },
  {
    name: 'OPERADOR DE IMPRESSAO',
    id: 937,
  },
  {
    name: 'OPERADOR DE INJETORA',
    id: 938,
  },
  {
    name: 'OPERADOR DE LOGISTICA',
    id: 939,
  },
  {
    name: 'OPERADOR DE LOJA',
    id: 940,
  },
  {
    name: 'OPERADOR DE MANUFATURA',
    id: 941,
  },
  {
    name: 'OPERADOR DE MAQUINA COPIADORA',
    id: 942,
  },
  {
    name: 'OPERADOR DE MAQUINA CORTE DOBRA',
    id: 943,
  },
  {
    name: 'OPERADOR DE MAQUINA DE BORDADO',
    id: 944,
  },
  {
    name: 'OPERADOR DE MAQUINA III',
    id: 945,
  },
  {
    name: 'OPERADOR DE MAQUINAS',
    id: 946,
  },
  {
    name: 'OPERADOR DE MAQUINAS CLASSIFICADORA E TABULADORA',
    id: 947,
  },
  {
    name: 'OPERADOR DE MONITORAMENTO',
    id: 948,
  },
  {
    name: 'OPERADOR DE MONTAGEM DE CILINDROS E MANCAIS',
    id: 949,
  },
  {
    name: 'OPERADOR DE MOTO SERRA',
    id: 950,
  },
  {
    name: 'OPERADOR DE MOVIMENTO CONFERENTE',
    id: 951,
  },
  {
    name: 'OPERADOR DE MÁQUINAS FIXAS',
    id: 952,
  },
  {
    name: 'OPERADOR DE PAINEL CENTRAL',
    id: 953,
  },
  {
    name: 'OPERADOR DE PONTE ROLANTE',
    id: 954,
  },
  {
    name: 'OPERADOR DE PRENSA',
    id: 955,
  },
  {
    name: 'OPERADOR DE PRODUCAO',
    id: 956,
  },
  {
    name: 'OPERADOR DE PRODUCAO II',
    id: 957,
  },
  {
    name: 'OPERADOR DE PRODUCAO III',
    id: 958,
  },
  {
    name: 'OPERADOR DE PÁCARREGADEIRA',
    id: 959,
  },
  {
    name: 'OPERADOR DE RECAPAGEM',
    id: 960,
  },
  {
    name: 'OPERADOR DE SECADOR (TRATAMENTOS QUÍMICOS E AFINS)',
    id: 961,
  },
  {
    name: 'OPERADOR DE SERRAS',
    id: 962,
  },
  {
    name: 'OPERADOR DE SISTEMAS',
    id: 963,
  },
  {
    name: 'OPERADOR DE TECELAGEM',
    id: 964,
  },
  {
    name: 'OPERADOR DE TELEMARKETING',
    id: 965,
  },
  {
    name: 'OPERADOR DE TORNO MECANICO',
    id: 966,
  },
  {
    name: 'OPERADOR DE TRATOR DE LÂMINA',
    id: 967,
  },
  {
    name: 'OPERADOR DE USINA',
    id: 968,
  },
  {
    name: 'OPERADOR DE USINAGEM',
    id: 969,
  },
  {
    name: 'OPERADOR DE VENDAS',
    id: 970,
  },
  {
    name: 'OPERADOR I',
    id: 971,
  },
  {
    name: 'OPERADOR II',
    id: 972,
  },
  {
    name: 'OPERADOR III',
    id: 973,
  },
  {
    name: 'OPERADOR INDUSTRIAL',
    id: 974,
  },
  {
    name: 'OPERADOR MULTIFUNCIONAL',
    id: 975,
  },
  {
    name: 'OPERADOR(A) ACABAMENTO',
    id: 976,
  },
  {
    name: 'OPERADOR(A) DE MAQUINA',
    id: 977,
  },
  {
    name: 'OPERADORA',
    id: 978,
  },
  {
    name: 'OPERADORA DE CAIXA',
    id: 979,
  },
  {
    name: 'OPERADORA DE COBRANCA JR',
    id: 980,
  },
  {
    name: 'OPERADORA DE MAQUINA',
    id: 981,
  },
  {
    name: 'OPERADORA DE TELEMARKENTING',
    id: 982,
  },
  {
    name: 'OPERADORA INDUSTRIAL',
    id: 983,
  },
  {
    name: 'ORIENTADOR (A)',
    id: 984,
  },
  {
    name: 'ORIENTADOR DE LOJA/ESTACIONAMENTO',
    id: 985,
  },
  {
    name: 'ORIENTADORA',
    id: 986,
  },
  {
    name: 'ORIENTADORA EDUCACIONAL',
    id: 987,
  },
  {
    name: 'OURIVES',
    id: 988,
  },
  {
    name: 'OUTRAS RENDAS',
    id: 989,
  },
  {
    name: 'OUTROS BOMBEIROS',
    id: 990,
  },
  {
    name: 'OUTROS COMERCIANTES (COMÉRCIO ATACADISTA E VAREJISTA)',
    id: 991,
  },
  {
    name: 'OUTROS DESPACHANTES',
    id: 992,
  },
  {
    name: 'OUTROS ENCANADORES E INSTALADORES DE TUBULAÇOES',
    id: 993,
  },
  {
    name: 'OUTROS GARÇONS',
    id: 994,
  },
  {
    name: 'OUTROS GUARDAS DE SEGURANÇA E TRABALHADORES ASSEMELHADOS',
    id: 995,
  },
  {
    name: 'OUTROS PINTORES DE OBRAS E DE ESTRUTURAS METÁLICAS',
    id: 996,
  },
  {
    name: 'OUTROS RECEPCIONISTAS',
    id: 997,
  },
  {
    name: 'OUTROS SECRETÁRIOS',
    id: 998,
  },
  {
    name: 'OUTROS SUPERVISORES DE VENDAS E TRABALHADORES ASSEMELHADOS',
    id: 999,
  },
  {
    name: 'OUTROS TRABALHADORES DA MOVIMENTAÇAO DE CARGAS E DESCARGAS',
    id: 1000,
  },
  {
    name: 'OUTROS TRABALHADORES DE SERVIÇOS DE PROTEÇAO E SEGURANÇA NAO',
    id: 1001,
  },
  {
    name: 'OUTROS VENDEDORES AMBULANTES',
    id: 1002,
  },
  {
    name: 'OUTROS VENDEDORES DE COMÉRCIO ATACADISTA',
    id: 1003,
  },
  {
    name: 'OUTROS VENDEDORES PRACISTAS',
    id: 1004,
  },
  {
    name: 'PACOTEIRO',
    id: 1005,
  },
  {
    name: 'PADEIRO',
    id: 1006,
  },
  {
    name: 'PADEIRO CONFEITEIRO',
    id: 1007,
  },
  {
    name: 'PAISAGISMO',
    id: 1008,
  },
  {
    name: 'PANFLETAGEM',
    id: 1009,
  },
  {
    name: 'PANFLETEIRA',
    id: 1010,
  },
  {
    name: 'PASSADEIRA',
    id: 1011,
  },
  {
    name: 'PASSADOR',
    id: 1012,
  },
  {
    name: 'PASTELEIRA',
    id: 1013,
  },
  {
    name: 'PASTOR',
    id: 1014,
  },
  {
    name: 'PASTORA AUXILIAR',
    id: 1015,
  },
  {
    name: 'PECUARISTA',
    id: 1016,
  },
  {
    name: 'PEDAGOGA',
    id: 1017,
  },
  {
    name: 'PEDAGOGO',
    id: 1018,
  },
  {
    name: 'PEDREIRO',
    id: 1019,
  },
  {
    name: 'PEDREIRO (CHAMINÉS INDUSTRIAIS)',
    id: 1020,
  },
  {
    name: 'PEDREIRO (EDIFICAÇOES)',
    id: 1021,
  },
  {
    name: 'PENCIONISTA',
    id: 1022,
  },
  {
    name: 'PENSAO ALIMENTICIA',
    id: 1023,
  },
  {
    name: 'PENSAO POR MORTE',
    id: 1024,
  },
  {
    name: 'PENSIONISTA',
    id: 1025,
  },
  {
    name: 'PENSIONISTA E AUTONOMA',
    id: 1026,
  },
  {
    name: 'PENSIONISTA E PROFESSORA',
    id: 1027,
  },
  {
    name: 'PERFUMISTA',
    id: 1028,
  },
  {
    name: 'PERSONAL TRAINER',
    id: 1029,
  },
  {
    name: 'PESADOR DE PRODUTOS QUIMICOS',
    id: 1030,
  },
  {
    name: 'PESCADOR',
    id: 1031,
  },
  {
    name: 'PESCADOR ARTESANAL',
    id: 1032,
  },
  {
    name: 'PESQUISADOR',
    id: 1033,
  },
  {
    name: 'PINTOR',
    id: 1034,
  },
  {
    name: 'PINTOR DE OBRAS',
    id: 1035,
  },
  {
    name: 'PINTOR DE VEÍCULOS',
    id: 1036,
  },
  {
    name: 'PINTOR INDUSTRIAL',
    id: 1037,
  },
  {
    name: 'PISCINEIRO',
    id: 1038,
  },
  {
    name: 'PIZZAIOLO',
    id: 1039,
  },
  {
    name: 'PLANFETAR',
    id: 1040,
  },
  {
    name: 'PODOLOGA (O)',
    id: 1041,
  },
  {
    name: 'POLICIAL CIVIL',
    id: 1042,
  },
  {
    name: 'POLICIAL MILITAR',
    id: 1043,
  },
  {
    name: 'POLIDOR DE CARROS',
    id: 1044,
  },
  {
    name: 'POLIDOR DE METAIS',
    id: 1045,
  },
  {
    name: 'POLIVALENTE',
    id: 1046,
  },
  {
    name: 'PORTEIRA',
    id: 1047,
  },
  {
    name: 'PORTEIRO',
    id: 1048,
  },
  {
    name: 'PORTEIRO DE EDIFÍCIO',
    id: 1049,
  },
  {
    name: 'PRENSISTA',
    id: 1050,
  },
  {
    name: 'PREPARADEIRA',
    id: 1051,
  },
  {
    name: 'PREPARADOR',
    id: 1052,
  },
  {
    name: 'PREPARADOR DE MAQUINAS',
    id: 1053,
  },
  {
    name: 'PREPARADOR DE PINTURA',
    id: 1054,
  },
  {
    name: 'PREPARADOR DE SUCATA E APARAS',
    id: 1055,
  },
  {
    name: 'PREPARADOR DE TINTAS',
    id: 1056,
  },
  {
    name: 'PRODUTOR AGROPECUÁRIO',
    id: 1057,
  },
  {
    name: 'PRODUTOR AGRÍCOLA POLIVALENTE',
    id: 1058,
  },
  {
    name: 'PRODUTOR DE EVENTOS',
    id: 1059,
  },
  {
    name: 'PROFESSOR (O)',
    id: 1060,
  },
  {
    name: 'PROFESSOR DE 1ª A 4ª SÉRIE (ENSINO DE 1º GRAU)',
    id: 1061,
  },
  {
    name: 'PROFESSOR DE EDUCACAO FISICA',
    id: 1062,
  },
  {
    name: 'PROFESSOR DE MUSICA',
    id: 1063,
  },
  {
    name: 'PROFESSOR(A)',
    id: 1064,
  },
  {
    name: 'PROFESSORA',
    id: 1065,
  },
  {
    name: 'PROFESSORA ADJUNTO - A -',
    id: 1066,
  },
  {
    name: 'PROFESSORA DE EDUCAÇÃO FISICA',
    id: 1067,
  },
  {
    name: 'PROFESSORA DE ENSINO SUPERIOR',
    id: 1068,
  },
  {
    name: 'PROGETISTA',
    id: 1069,
  },
  {
    name: 'PROGRAMADOR',
    id: 1070,
  },
  {
    name: 'PROGRAMADOR DE COMPUTADOR',
    id: 1071,
  },
  {
    name: 'PROGRAMADOR DE PRODUCAO',
    id: 1072,
  },
  {
    name: 'PROGRAMADOR DE SISTEMA',
    id: 1073,
  },
  {
    name: 'PROJETISTA',
    id: 1074,
  },
  {
    name: 'PROMOTER',
    id: 1075,
  },
  {
    name: 'PROMOTOR',
    id: 1076,
  },
  {
    name: 'PROMOTOR DE EVENTOS',
    id: 1077,
  },
  {
    name: 'PROMOTOR DE MARKETING',
    id: 1078,
  },
  {
    name: 'PROMOTOR DE MERCHANDISER',
    id: 1079,
  },
  {
    name: 'PROMOTOR DE VENDAS',
    id: 1080,
  },
  {
    name: 'PROMOTORA',
    id: 1081,
  },
  {
    name: 'PROMOTORA COMERCIAL',
    id: 1082,
  },
  {
    name: 'PROMOTORA DE EVENTOS',
    id: 1083,
  },
  {
    name: 'PROMOTORA DE VENDAS',
    id: 1084,
  },
  {
    name: 'PROPAGANDISTA',
    id: 1085,
  },
  {
    name: 'PROPIETARIO (A)',
    id: 1086,
  },
  {
    name: 'PROPRIETARIA',
    id: 1087,
  },
  {
    name: 'PROPRIETARIO(A)',
    id: 1088,
  },
  {
    name: 'PROTETICA',
    id: 1089,
  },
  {
    name: 'PROTETICO',
    id: 1090,
  },
  {
    name: 'PROTETICO DENTARIO',
    id: 1091,
  },
  {
    name: 'PSICOLOGA',
    id: 1092,
  },
  {
    name: 'PSICOLOGO',
    id: 1093,
  },
  {
    name: 'PUBLICITARIA',
    id: 1094,
  },
  {
    name: 'PUBLICITARIO',
    id: 1095,
  },
  {
    name: 'QUEIMADOR',
    id: 1096,
  },
  {
    name: 'QUIMICO',
    id: 1097,
  },
  {
    name: 'RADIALISTA',
    id: 1098,
  },
  {
    name: 'RADIOLOGISTA',
    id: 1099,
  },
  {
    name: 'RASTELEIRO',
    id: 1100,
  },
  {
    name: 'REBARBADOR DE METAIS',
    id: 1101,
  },
  {
    name: 'REBOBINADOR(A)',
    id: 1102,
  },
  {
    name: 'RECEBEDOR DE APOSTAS (LOTERIAS)',
    id: 1103,
  },
  {
    name: 'RECEPCIONISTA',
    id: 1104,
  },
  {
    name: 'RECEPCIONISTA BILINGUE',
    id: 1105,
  },
  {
    name: 'RECEPCIONISTA DE CONSULTÓRIO MÉDICO OU DENTÁRIO',
    id: 1106,
  },
  {
    name: 'RECEPCIONISTA DE HOTEL',
    id: 1107,
  },
  {
    name: 'RECICLADOR',
    id: 1108,
  },
  {
    name: 'RECICLAGEM',
    id: 1109,
  },
  {
    name: 'RECPECIONISTA',
    id: 1110,
  },
  {
    name: 'RECREACIONISTA',
    id: 1111,
  },
  {
    name: 'RECREADORA',
    id: 1112,
  },
  {
    name: 'RECUPERADOR DE CREDITO',
    id: 1113,
  },
  {
    name: 'RECURSOS HUMANOS',
    id: 1114,
  },
  {
    name: 'REPORTER',
    id: 1115,
  },
  {
    name: 'REPOSITOR',
    id: 1116,
  },
  {
    name: 'REPOSITORA',
    id: 1117,
  },
  {
    name: 'REPRESENTANTE COMERCIAL',
    id: 1118,
  },
  {
    name: 'REPRESENTANTE DE VENDAS',
    id: 1119,
  },
  {
    name: 'RESPONSAVEL VISUAL MERCHANDISE',
    id: 1120,
  },
  {
    name: 'RETALHADOR DE CARNE',
    id: 1121,
  },
  {
    name: 'RETIFICADOR',
    id: 1122,
  },
  {
    name: 'REVENDEDOR',
    id: 1123,
  },
  {
    name: 'REVISOR',
    id: 1124,
  },
  {
    name: 'REVISOR DE TECIDOS',
    id: 1125,
  },
  {
    name: 'REVISORA',
    id: 1126,
  },
  {
    name: 'ROCADOR',
    id: 1127,
  },
  {
    name: 'SAFRISTA',
    id: 1128,
  },
  {
    name: 'SALADEIRA',
    id: 1129,
  },
  {
    name: 'SALGADEIRA',
    id: 1130,
  },
  {
    name: 'SALGADEIRO (A)',
    id: 1131,
  },
  {
    name: 'SAPATEIRO',
    id: 1132,
  },
  {
    name: 'SAQUEIRO',
    id: 1133,
  },
  {
    name: 'SARGENTO (1º)',
    id: 1134,
  },
  {
    name: 'SARGENTO (2º)',
    id: 1135,
  },
  {
    name: 'SARGENTO (3º)',
    id: 1136,
  },
  {
    name: 'SECRETARIA',
    id: 1137,
  },
  {
    name: 'SECRETARIA (O)',
    id: 1138,
  },
  {
    name: 'SECRETARIA (O) EXECUTIVO',
    id: 1139,
  },
  {
    name: 'SECRETARIA ADMINISTRATIVA',
    id: 1140,
  },
  {
    name: 'SECRETARIA DO LAR',
    id: 1141,
  },
  {
    name: 'SECRETARIA EXECUTIVO',
    id: 1142,
  },
  {
    name: 'SECRETARIA RECEPCIONISTA',
    id: 1143,
  },
  {
    name: 'SECRETARIO ESCOLAR',
    id: 1144,
  },
  {
    name: 'SECRETARIO GERAL',
    id: 1145,
  },
  {
    name: 'SECRETARIO PARLAMENTAR',
    id: 1146,
  },
  {
    name: 'SECURITARIA',
    id: 1147,
  },
  {
    name: 'SEGURANCA',
    id: 1148,
  },
  {
    name: 'SEPARADOR',
    id: 1149,
  },
  {
    name: 'SEPARADOR DE PEDIDO',
    id: 1150,
  },
  {
    name: 'SERIGRAFISTA',
    id: 1151,
  },
  {
    name: 'SERINGUEIRO',
    id: 1152,
  },
  {
    name: 'SERRADOR DE MADEIRA',
    id: 1153,
  },
  {
    name: 'SERRADOR(B)',
    id: 1154,
  },
  {
    name: 'SERRALHEIRO',
    id: 1155,
  },
  {
    name: 'SERVENTE',
    id: 1156,
  },
  {
    name: 'SERVENTE DE LIMPEZA',
    id: 1157,
  },
  {
    name: 'SERVENTE DE OBRAS',
    id: 1158,
  },
  {
    name: 'SERVENTE DE PEDREIRO',
    id: 1159,
  },
  {
    name: 'SERVENTE ESCOLAR',
    id: 1160,
  },
  {
    name: 'SERVICOS GERAIS',
    id: 1161,
  },
  {
    name: 'SERVIDOR PUBLICO',
    id: 1162,
  },
  {
    name: 'SINALEIRO (PONTE ROLANTE)',
    id: 1163,
  },
  {
    name: 'SINDICA',
    id: 1164,
  },
  {
    name: 'SOCIA',
    id: 1165,
  },
  {
    name: 'SOCIA GERENTE',
    id: 1166,
  },
  {
    name: 'SOCIA PROPRIETARIA',
    id: 1167,
  },
  {
    name: 'SOCIO',
    id: 1168,
  },
  {
    name: 'SOCIO ADMINISTRADOR',
    id: 1169,
  },
  {
    name: 'SOCIO PROPRIETARIO',
    id: 1170,
  },
  {
    name: 'SOCIO(A) GERENTE',
    id: 1171,
  },
  {
    name: 'SOCORRISTA',
    id: 1172,
  },
  {
    name: 'SOLDADO',
    id: 1173,
  },
  {
    name: 'SOLDADOR',
    id: 1174,
  },
  {
    name: 'SOLDADOR GERAL',
    id: 1175,
  },
  {
    name: 'SUB GERENTE',
    id: 1176,
  },
  {
    name: 'SUBGERENTE',
    id: 1177,
  },
  {
    name: 'SUBTENENTE',
    id: 1178,
  },
  {
    name: 'SUCATEIRO',
    id: 1179,
  },
  {
    name: 'SUPERVISOR',
    id: 1180,
  },
  {
    name: 'SUPERVISOR ADMINISTRATIVO',
    id: 1181,
  },
  {
    name: 'SUPERVISOR COMERCIAL',
    id: 1182,
  },
  {
    name: 'SUPERVISOR DE ATENDIMENTO',
    id: 1183,
  },
  {
    name: 'SUPERVISOR DE COBRANCAS',
    id: 1184,
  },
  {
    name: 'SUPERVISOR DE GESSO',
    id: 1185,
  },
  {
    name: 'SUPERVISOR DE LOGISTICA',
    id: 1186,
  },
  {
    name: 'SUPERVISOR DE LOJA',
    id: 1187,
  },
  {
    name: 'SUPERVISOR DE MANUTENCAO',
    id: 1188,
  },
  {
    name: 'SUPERVISOR DE OBRAS',
    id: 1189,
  },
  {
    name: 'SUPERVISOR DE OPERACOES',
    id: 1190,
  },
  {
    name: 'SUPERVISOR DE PANIFICACAO',
    id: 1191,
  },
  {
    name: 'SUPERVISOR DE PRODUCAO',
    id: 1192,
  },
  {
    name: 'SUPERVISOR DE SEGURANCA',
    id: 1193,
  },
  {
    name: 'SUPERVISOR DE VENDAS',
    id: 1194,
  },
  {
    name: 'SUPERVISOR TECNICO',
    id: 1195,
  },
  {
    name: 'SUPERVISORA',
    id: 1196,
  },
  {
    name: 'SUPORTE TECNICO',
    id: 1197,
  },
  {
    name: 'SUSHI-MAN',
    id: 1198,
  },
  {
    name: 'TALHADEIRA',
    id: 1199,
  },
  {
    name: 'TALHADOR',
    id: 1200,
  },
  {
    name: 'TAPECEIRO (CENÁRIOS)',
    id: 1201,
  },
  {
    name: 'TATOADOR',
    id: 1202,
  },
  {
    name: 'TAXISTA',
    id: 1203,
  },
  {
    name: 'TEC. INFORMATICA',
    id: 1204,
  },
  {
    name: 'TECELAO',
    id: 1205,
  },
  {
    name: 'TECNICA EM ENFERMAGEM',
    id: 1206,
  },
  {
    name: 'TECNICA EM INFORMATICA',
    id: 1207,
  },
  {
    name: 'TECNICA EM RAIO X',
    id: 1208,
  },
  {
    name: 'TECNICA ENFERMAGEM',
    id: 1209,
  },
  {
    name: 'TECNICO',
    id: 1210,
  },
  {
    name: 'TECNICO ADMINISTRATIVO',
    id: 1211,
  },
  {
    name: 'TECNICO AGRICOLA',
    id: 1212,
  },
  {
    name: 'TECNICO AGROPECUARIO',
    id: 1213,
  },
  {
    name: 'TECNICO AUTOMOTIVO',
    id: 1214,
  },
  {
    name: 'TECNICO BANCARIO',
    id: 1215,
  },
  {
    name: 'TECNICO CONTABIL',
    id: 1216,
  },
  {
    name: 'TECNICO DE CONTABILIDADE',
    id: 1217,
  },
  {
    name: 'TECNICO DE ENFERMAGEM',
    id: 1218,
  },
  {
    name: 'TECNICO DE INFORMATICA',
    id: 1219,
  },
  {
    name: 'TECNICO DE INSPECAO',
    id: 1220,
  },
  {
    name: 'TECNICO DE LABORATORIO',
    id: 1221,
  },
  {
    name: 'TECNICO DE LABORATORIO DE ANALISES CLINICAS',
    id: 1222,
  },
  {
    name: 'TECNICO DE MANUTENCAO',
    id: 1223,
  },
  {
    name: 'TECNICO DE MANUTENCAO ELETRICA',
    id: 1224,
  },
  {
    name: 'TECNICO DE PRODUCAO',
    id: 1225,
  },
  {
    name: 'TECNICO DE QUALIDADE',
    id: 1226,
  },
  {
    name: 'TECNICO DE SANEAMENTO',
    id: 1227,
  },
  {
    name: 'TECNICO DE SEGURANCA DE TRABALHO',
    id: 1228,
  },
  {
    name: 'TECNICO DE SEGURANCA DO TRABALHO',
    id: 1229,
  },
  {
    name: 'TECNICO DE SERVICOS',
    id: 1230,
  },
  {
    name: 'TECNICO DE SOM',
    id: 1231,
  },
  {
    name: 'TECNICO DE SUPORTE',
    id: 1232,
  },
  {
    name: 'TECNICO DE TELECOMUNICACAO',
    id: 1233,
  },
  {
    name: 'TECNICO DE TELECOMUNICACOES',
    id: 1234,
  },
  {
    name: 'TECNICO EDUCACIONAL',
    id: 1235,
  },
  {
    name: 'TECNICO ELETROMECANICO',
    id: 1236,
  },
  {
    name: 'TECNICO ELETRONICA',
    id: 1237,
  },
  {
    name: 'TECNICO ELETRONICO',
    id: 1238,
  },
  {
    name: 'TECNICO ELETROTECNICO',
    id: 1239,
  },
  {
    name: 'TECNICO EM EDIFICACOES',
    id: 1240,
  },
  {
    name: 'TECNICO EM ELETRONICA',
    id: 1241,
  },
  {
    name: 'TECNICO EM ELETROTECNICA',
    id: 1242,
  },
  {
    name: 'TECNICO EM ENFERMAGEM',
    id: 1243,
  },
  {
    name: 'TECNICO EM FARMACIA',
    id: 1244,
  },
  {
    name: 'TECNICO EM INFORMATICA',
    id: 1245,
  },
  {
    name: 'TECNICO EM INSTALACAO',
    id: 1246,
  },
  {
    name: 'TECNICO EM INSTALACAO E MANUTENCAO',
    id: 1247,
  },
  {
    name: 'TECNICO EM LABORATORIO',
    id: 1248,
  },
  {
    name: 'TECNICO EM MANUTENCAO',
    id: 1249,
  },
  {
    name: 'TECNICO EM MANUTENCAO DE COMPUTADORES',
    id: 1250,
  },
  {
    name: 'TECNICO EM MECANICA',
    id: 1251,
  },
  {
    name: 'TECNICO EM RADIOLOGIA',
    id: 1252,
  },
  {
    name: 'TECNICO EM REFRIGERACAO',
    id: 1253,
  },
  {
    name: 'TECNICO EM SEGURANCA DO TRABALHO',
    id: 1254,
  },
  {
    name: 'TECNICO EM TELECOMUNICACOES',
    id: 1255,
  },
  {
    name: 'TECNICO EM TELEFONIA',
    id: 1256,
  },
  {
    name: 'TECNICO ENFERMAGEM DO TRABALHO',
    id: 1257,
  },
  {
    name: 'TECNICO INFORMATICA',
    id: 1258,
  },
  {
    name: 'TECNICO JUDICIARIO',
    id: 1259,
  },
  {
    name: 'TECNICO MANUTENCAO',
    id: 1260,
  },
  {
    name: 'TECNICO MECANICO',
    id: 1261,
  },
  {
    name: 'TECNICO OPERADOR',
    id: 1262,
  },
  {
    name: 'TECNICO QUIMICO',
    id: 1263,
  },
  {
    name: 'TECNICO SEGURANCA DO TRABALHO',
    id: 1264,
  },
  {
    name: 'TECNOLOGO',
    id: 1265,
  },
  {
    name: 'TELE ATENDENTE',
    id: 1266,
  },
  {
    name: 'TELE VENDAS',
    id: 1267,
  },
  {
    name: 'TELEFONISTA',
    id: 1268,
  },
  {
    name: 'TELEMARKTING',
    id: 1269,
  },
  {
    name: 'TENENTE, 2º',
    id: 1270,
  },
  {
    name: 'TERAPEUTA HOLISTICO',
    id: 1271,
  },
  {
    name: 'TERAPEUTA OCUPACIONAL',
    id: 1272,
  },
  {
    name: 'TESOUREIRO',
    id: 1273,
  },
  {
    name: 'TINTUREIRO',
    id: 1274,
  },
  {
    name: 'TOPOGRAFO',
    id: 1275,
  },
  {
    name: 'TORNEIRO MECANICO',
    id: 1276,
  },
  {
    name: 'TORNEIRO MECÂNICO',
    id: 1277,
  },
  {
    name: 'TOSADOR',
    id: 1278,
  },
  {
    name: 'TOSADORA E INSTRUMENTADORA CIRURGICA',
    id: 1279,
  },
  {
    name: 'TOZADORA',
    id: 1280,
  },
  {
    name: 'TRABALHADOR AGROPECUÁRIO POLIVALENTE',
    id: 1281,
  },
  {
    name: 'TRABALHADOR AGRÍCOLA POLIVALENTE',
    id: 1282,
  },
  {
    name: 'TRABALHADOR DA AVICULTURA',
    id: 1283,
  },
  {
    name: 'TRABALHADOR DA CULTURA DE BANANA',
    id: 1284,
  },
  {
    name: 'TRABALHADOR DA CULTURA DE HORTALIÇAS',
    id: 1285,
  },
  {
    name: 'TRABALHADOR DA MOVIMENTAÇAO DE CARGA E DESCARGA DE MERCADORI',
    id: 1286,
  },
  {
    name: 'TRABALHADOR DA PECUÁRIA (GADO LEITEIRO)',
    id: 1287,
  },
  {
    name: 'TRABALHADOR DA SUINOCULTURA',
    id: 1288,
  },
  {
    name: 'TRABALHADOR DE FABRICAÇAO DE SORVETE',
    id: 1289,
  },
  {
    name: 'TRABALHADOR HORTIGRANJEIRO',
    id: 1290,
  },
  {
    name: 'TRABALHADOR RURAL',
    id: 1291,
  },
  {
    name: 'TRABALHADOR VOLANTE DA AGRICULTURA',
    id: 1292,
  },
  {
    name: 'TRABALHADORES DE SERVIÇOS GERAIS (SERVIÇOS DE CONSERVACAO',
    id: 1293,
  },
  {
    name: 'TRANSPORTADOR',
    id: 1294,
  },
  {
    name: 'TRATADOR',
    id: 1295,
  },
  {
    name: 'TRATORISTA',
    id: 1296,
  },
  {
    name: 'TRATORISTA AGRÍCOLA',
    id: 1297,
  },
  {
    name: 'TREINADOR',
    id: 1298,
  },
  {
    name: 'VAQUEIRO',
    id: 1299,
  },
  {
    name: 'VARREDOR',
    id: 1300,
  },
  {
    name: 'VENDEDOR',
    id: 1301,
  },
  {
    name: 'VENDEDOR A DOMICILIO',
    id: 1302,
  },
  {
    name: 'VENDEDOR AMBULANTE',
    id: 1303,
  },
  {
    name: 'VENDEDOR AUTONOMO',
    id: 1304,
  },
  {
    name: 'VENDEDOR DE AUTOMOVEIS',
    id: 1305,
  },
  {
    name: 'VENDEDOR DE COMÉRCIO ATACADISTA',
    id: 1306,
  },
  {
    name: 'VENDEDOR DE COMÉRCIO VAREJISTA',
    id: 1307,
  },
  {
    name: 'VENDEDOR EXTERNO',
    id: 1308,
  },
  {
    name: 'VENDEDOR TECNICO',
    id: 1309,
  },
  {
    name: 'VENDEDOR(A) EXTERNO',
    id: 1310,
  },
  {
    name: 'VENDEDORA',
    id: 1311,
  },
  {
    name: 'VENDEDORA AUTONOMA',
    id: 1312,
  },
  {
    name: 'VENDEDORA AUTONOMO',
    id: 1313,
  },
  {
    name: 'VENDEDORA DE BIJU',
    id: 1314,
  },
  {
    name: 'VENDEDORA PROMOTORA',
    id: 1315,
  },
  {
    name: 'VERDUREIRO',
    id: 1316,
  },
  {
    name: 'VEREADOR',
    id: 1317,
  },
  {
    name: 'VETERINARIA (O)',
    id: 1318,
  },
  {
    name: 'VETERINARIO',
    id: 1319,
  },
  {
    name: 'VIDRACEIRO',
    id: 1320,
  },
  {
    name: 'VIGIA',
    id: 1321,
  },
  {
    name: 'VIGILANTE',
    id: 1322,
  },
  {
    name: 'VIGILANTE SANITARIO',
    id: 1323,
  },
  {
    name: 'VISTORIADOR',
    id: 1324,
  },
  {
    name: 'VISTORIADOR DE VEICULOS',
    id: 1325,
  },
  {
    name: 'VULCANIZADOR DE PNEUMÁTICOS',
    id: 1326,
  },
  {
    name: 'WEB DESIGNER',
    id: 1327,
  },
  {
    name: 'ZELADOR DO EDIFÍCIO',
    id: 1328,
  },
  {
    name: 'ZELADOR(A)',
    id: 1329,
  },
  {
    name: 'ZELADORA',
    id: 1330,
  },
  {
    name: 'ZOOTECNISTA',
    id: 1331,
  },
  {
    name: 'APOSENTADA AUTONOMO',
    id: 1332,
  },
  {
    name: 'APOSENTADA E PROFESSORA',
    id: 1333,
  },
  {
    name: 'AUXILIAR DE SERVIÇOS ESCOLARES',
    id: 1334,
  },
  {
    name: 'AUXILIAR JUDICIARIO',
    id: 1335,
  },
  {
    name: 'BALCONISTA GERENTE',
    id: 1336,
  },
  {
    name: 'CHEFE DE PISTA',
    id: 1337,
  },
  {
    name: 'MONITOR DE ATENDIMENTO',
    id: 1338,
  },
]