import React from 'react';
import styled from 'styled-components';
import { useAppDispatch } from '../redux/hooks';
import applicationReducer from '../redux/reducers/application.reducer';
import MobileHeader from './MobileHeader';
import NextBackFooterButtons from './NextBackFooterButtons';

interface ApplicationLayoutProps {
  labelPreviousButton?: string;
  labelNextButton?: string;
  isNextButtonLoading?: boolean;
  isNextButtonEnabled?: boolean;

  children?: any;

  subtitle: string;
  title: string;

  overrideNextPage?: (ok: Function) => void;
  overridePreviousPage?: (ok: Function) => void;
}
let isButtonPressed = false;

const ApplicationLayout = (props: ApplicationLayoutProps) => {
  const dispatch = useAppDispatch();
  const {
    labelPreviousButton,
    isNextButtonLoading,
    isNextButtonEnabled,
    labelNextButton,
    children,
    subtitle,
    title,
    overrideNextPage,
    overridePreviousPage,
  } = props;

  const handlePreviousPage = () => {
    if (overridePreviousPage) {
      overridePreviousPage(() =>
        dispatch(applicationReducer.actions.previousPage())
      );
    } else {
      dispatch(applicationReducer.actions.previousPage());
    }
  };

  const handleNextPage = () => {
    if (isButtonPressed) return;
    isButtonPressed = true;

    if (overrideNextPage) {
      overrideNextPage(() => dispatch(applicationReducer.actions.nextPage()));
    } else {
      dispatch(applicationReducer.actions.nextPage());
    }
    setTimeout(() => (isButtonPressed = false), 1000);
  };

  return (
    <Container>
      <MobileHeader />

      <PageContainer>
        <TitleContainer>
          <Title> {title} </Title>
          <SubTitle> {subtitle}</SubTitle>
        </TitleContainer>

        <Body>{children}</Body>

        <Footer>
          <NextBackFooterButtons
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            isNextButtonEnabled={isNextButtonEnabled}
            isNextButtonLoading={isNextButtonLoading}
            labelNextButton={labelNextButton}
            labelPreviousButton={labelPreviousButton}
          />
        </Footer>
      </PageContainer>
    </Container>
  );
};

export default ApplicationLayout;

const Container = styled.div`
  height: 100%;

  text-align: justify;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  // margin: 5%;
`;

const PageContainer = styled.div`
  height: 100%;

  text-align: justify;

  margin: 8%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  // padding: 0% 5% 5% 5%;
`;

const TitleContainer = styled.div`
  height: 15%;

  // margin-bottom: min(30px, 2vh);

  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const Title = styled.span`
  // font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;

  letter-spacing: 0.18px;
  align-self: left;

  color: #000000;
`;

const SubTitle = styled.span`
  // font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #000000;

  margin-top: 2vh;
`;

const Body = styled.div`
  // height: 77%;

  flex: 1;
  margin-bottom: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const Footer = styled.div`
  // height: 8%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
