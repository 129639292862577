import React from 'react';
import styled from 'styled-components';

interface Props {
  labelNextButton?: string;
  labelPreviousButton?: string;

  isNextButtonEnabled?: boolean;
  isNextButtonLoading?: boolean;

  handleNextPage: () => void;
  handlePreviousPage: () => void;

  onlyNextButton?: boolean;
}

const NextBackFooterButtons = ({
  handleNextPage,
  handlePreviousPage,
  isNextButtonLoading,
  labelPreviousButton,
  isNextButtonEnabled,
  labelNextButton,
  onlyNextButton,
}: Props) => {
  if (onlyNextButton) {
    return (
      <SingleNextButton loading={isNextButtonLoading} disabled={!isNextButtonEnabled} onClick={handleNextPage}>
        {labelNextButton ?? 'Próximo'}
      </SingleNextButton>
    );
  }

  return (
    <>
      <BackButton onClick={handlePreviousPage}>{labelPreviousButton ?? 'Voltar'}</BackButton>
      <NextButton loading={isNextButtonLoading} disabled={!isNextButtonEnabled} onClick={handleNextPage}>
        {labelNextButton ?? 'Próximo'}
      </NextButton>
    </>
  );
};

export default NextBackFooterButtons;

const BackButton = styled.button`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;

  color: #11b048 !important;

  // BUTTON
  padding: 10px !important;
  background: #fff !important;
  border: 1px solid #11b048 !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;

  width: 80% !important;
  height: 100% !important;
`;

const NextButton = styled.button<{ loading?: boolean; disabled?: boolean }>`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;

  text-align: center;

  color: #ffffff !important;

  // BUTTON
  padding: 10px !important;
  border: 1px solid #11b048 !important;
  background: #11b048 !important;
  border-radius: 10px !important;

  width: 80% !important;
  // height: 100% !important;
  margin-left: 6vw !important;

  // TODO - Add hover
  ${(props: any) => (props && props.disabled ? 'opacity: 0.3;' : '')}// &:hover {
  //   cursor: pointer;
  //   transition: 0.3s;
  //   background-color: #11b048;
  //   opacity: 0.5;
  // }

  // &:focus {
  //   background-color: #11b048;
  // }

  // &:active {
  //   background-color: #11b048;
  // }
`;

const SingleNextButton = styled.button<{ loading?: boolean; disabled?: boolean }>`
  // TEXT
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;

  text-align: center;

  color: #ffffff !important;

  // BUTTON
  padding: 10px !important;
  border: 1px solid #11b048 !important;
  background: #11b048 !important;
  border-radius: 5px !important;

  width: 100%;

  ${(props: any) => (props && props.disabled ? 'opacity: 0.3;' : '')}// &:hover {
`;
