// TODO(lucas.citolin): refactor. CP from credit-spa
export class EmailUtils {
  static emailValidatorRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  static validateEmail(email?: string) {
    if (!email) {
      return false
    }
    email = email.replace(/\s/g, '')
    if (email.length === 0) {
      return true
    }
  
    return this.emailValidatorRegex.test(email)
  
  }
}