import { ReactComponent as Onboarding0SVG } from '../../assets/onboarding/onboarding_0.svg';
import { ReactComponent as Onboarding1SVG } from '../../assets/onboarding/onboarding_1.svg';
import { ReactComponent as Onboarding2SVG } from '../../assets/onboarding/onboarding_2.svg';
import styled from 'styled-components';

interface OnboardingItem {
  Image: any;
  title: any;
  subtitle: string;
}

export const OnboardingItemOne = () => {
  return (
    <OnboardingItemContainer>
      <ImageContainer>
        <Onboarding0SVG />
      </ImageContainer>
      <TitleContainer>
        <Title>
          <StrongTitle>Crédito acessível</StrongTitle> para você
        </Title>
        <SubTitle>Faça agora o seu cadastro e tenha acesso ao seu crédito Ume!</SubTitle>
      </TitleContainer>
    </OnboardingItemContainer>
  );
};

export const OnboardingItemTwo = () => {
  return (
    <OnboardingItemContainer>
      <ImageContainer style={{ marginLeft: '8%' }}>
        <Onboarding1SVG />
      </ImageContainer>
      <TitleContainer>
        <Title>
          <StrongTitle>Sem anuidade</StrongTitle> e <StrongTitle>sem burocracia</StrongTitle>
        </Title>
        <SubTitle>Sem taxas abusivas. Você faz seu próprio cadastro e só paga se usar.</SubTitle>
      </TitleContainer>
    </OnboardingItemContainer>
  );
};

export const OnboardingItemThree = () => {
  return (
    <OnboardingItemContainer>
      <ImageContainer style={{ marginLeft: '8%' }}>
        <Onboarding2SVG />
      </ImageContainer>
      <TitleContainer>
        <Title>
          <StrongTitle>Mais de 180 lojas</StrongTitle> para parcelar suas compras
        </Title>
        <SubTitle>
          Escolha entre os mais de 180 parceiros da Ume para usar seu crédito e acompanhe o histórico pelo aplicativo - a gente facilita para você!
        </SubTitle>
      </TitleContainer>
    </OnboardingItemContainer>
  );
};

const OnboardingItemContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;

  height: 100%;

  // flex: 80% 20%;
`;

const ImageContainer = styled.div`
  // background-color: blue;

  display: flex;
  justify-content: flex-start;
  align-items: end;

  height: 50%;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin: 8%;
`;

const StrongTitle = styled.span`
  font-weight: 700;
`;

const Title = styled.span`
  text-align: left;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;

  color: #454b46;
`;
const SubTitle = styled.span`
  margin-top: 2vh;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #7a7e7a;
`;
