import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CallCenterSVGIcon } from '../assets/icons/call_center.svg';
import { useAppSelector } from '../redux/hooks';
import UmeConfigs from '../utils/UmeConfigs';
import WhatsappUtils from '../utils/WhatsappUtils';

const ApplicationAlreadyExistsPage = () => {
  const cpf = useAppSelector((state) => state.application.cpf);
  
  return (
    <Container>
      <CallCenterSVGIcon fontSize={'large'} style={{ color: '#FEAC7C' }} />
      <StrongText> Você já possui um cadastro </StrongText>
      <Explanation>
        No momento não podemos prosseguir com o seu registro por aqui. Se tiver alguma dúvida, acesse nossa {' '} <UnderlinedLink
          href={UmeConfigs.APPLICATION_HELP}
          target='_blank'
        >
          Central de Ajuda.
        </UnderlinedLink>
      </Explanation>
    </Container>
  );
};

export default ApplicationAlreadyExistsPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10%;

  height: 75%;
`;

const UnderlinedLink = styled.a`
  color: #454b46;
`;

const StrongText = styled.h1`
  margin-top: 3vh;

  text-align: center;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;

  /* Color/Dark/400 */

  color: #454b46;
`;

const Explanation = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;
  letter-spacing: 0.15px;

  /* Color/Dark/300 */

  color: #7a7e7a;
`;
