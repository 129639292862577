import { ZipCodeSearchError, ZipcodeSearchErrors } from './ZipcodeSearchError';

const ViaCep = require('node-viacep').default;
const viacep = new ViaCep();

export interface ZipCodeAddress {
  cep: string;
  logradouro: string;
  complemento?: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
}

export default class ZipCodeSearchService {
  static searchAddressByZipCode = async (zipcode: string): Promise<ZipCodeAddress | ZipCodeSearchError> => {
    return viacep.address
      .getJson(zipcode)
      .then((data: any) => data.json())
      .then((data: ZipCodeAddress) => {
        if (!data.uf) {
          return new ZipCodeSearchError(ZipcodeSearchErrors.ZIPCODE_NOT_FOUND);
        }

        return data;
      })
      .catch((error: any) => {
        return new ZipCodeSearchError(ZipcodeSearchErrors.INVALID_ZIPCODE);
      });
  };
}
