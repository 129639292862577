import React, { useState } from 'react';
import ApplicationLayout from '../layout/ApplicationLayout';
import { bffService } from '../services';
import { BffServiceError } from '../services/bff/BffServiceError';
import BiometryErrorsMapper from '../utils/BiometryErrorsMapper';
import TakePhotoOrganism from '../components/TakePhotoOrganism';
import Base64Utils from '../utils/Base64Utils';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import applicationReducer from '../redux/reducers/application.reducer';
import { PortraitSDK } from '../utils/PortraitPhoto';

const BiometryPage = () => {
  const dispatch = useAppDispatch();

  const { successfulBiometryImageBase64 } = useAppSelector((state) => ({
    successfulBiometryImageBase64:
      state.application.successfulBiometryImageBase64,
  }));

  const [imageErrorExplanation, setImageErrorExplanation] =
    useState<string>('');
  const [numberOfAttempts, setNumberOfAttempts] = useState<number>(0);
  const [takenImage, setTakenImage] = useState<string | undefined>(
    successfulBiometryImageBase64
      ? Base64Utils.addPrefixImageData(successfulBiometryImageBase64)
      : successfulBiometryImageBase64
  );

  const [isSubmittingBiometry, setIsSubmittingBiometry] =
    useState<boolean>(false);
  const [isBiometrySubmitted, setIsBiometrySubmitted] = useState<boolean>(
    !!successfulBiometryImageBase64
  );

  const handlePhoto = async (
    imageBase64: string,
    photoVersion?: any,
    logObject?: any
  ) => {
    setImageErrorExplanation('');

    setTakenImage(Base64Utils.addPrefixImageData(imageBase64));

    setIsSubmittingBiometry(true);
    const submittedBiometry = await bffService.submitBiometry(
      Base64Utils.removeDataUriScheme(imageBase64),
      photoVersion,
      logObject
    );
    if (submittedBiometry instanceof BffServiceError) {
      setIsSubmittingBiometry(false);
      setNumberOfAttempts(numberOfAttempts + 1);
      setImageErrorExplanation(
        BiometryErrorsMapper.mapErrorToUIString(
          submittedBiometry.message as any
        )
      );
      return;
    }

    dispatch(applicationReducer.actions.onBiometrySuccess({ imageBase64 }));
    setIsBiometrySubmitted(true);
    setIsSubmittingBiometry(false);
  };

  return (
    <ApplicationLayout
      title={'Vamos precisar de uma foto do seu rosto'}
      subtitle={''}
      isNextButtonEnabled={isBiometrySubmitted}
    >
      <TakePhotoOrganism
        imageErrorExplanation={imageErrorExplanation}
        isHandlingPhoto={isSubmittingBiometry}
        isPhotoValid={isBiometrySubmitted}
        useFrontalCamera={true}
        onPhoto={handlePhoto}
        cameraSDK={PortraitSDK.portraitSdkinstance}
        takenImage={takenImage}
      />
    </ApplicationLayout>
  );
};

export default BiometryPage;
