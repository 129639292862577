import React from 'react';
import styled from 'styled-components';
import { ReactComponent as SadFaceSVGIcon } from '../assets/icons/sad_face.svg';
import UmeConfigs from '../utils/UmeConfigs';

const ApplicationDeniedPage = () => {
  return (
    <Container>
      <SadFaceSVGIcon fontSize={'large'} style={{ color: '#FEAC7C' }} />
      <StrongText> Poxa, não conseguimos aprovar seu cadastro </StrongText>
      <Explanation>
        Fique tranquilo! Não significa que você tem alguma pendência em seu nome. 
        <br /> <br />
        Nossa análise se baseia em informações de bancos de dados públicos e em critérios internos, por isso, no momento, não conseguimos aprovar o seu crédito Ume.
        <br /> <br />
        Acesse nossa {' '}
        <UnderlinedLink href={UmeConfigs.APPLICATION_HELP} target='_blank'>
          Central de Ajuda
        </UnderlinedLink>
        {' '} para saber mais.
      </Explanation>
    </Container>
  );
};

export default ApplicationDeniedPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10%;

  height: 75%;
`;

const UnderlinedLink = styled.a`
  color: #454b46;
`;

const StrongText = styled.h1`
  margin-top: 3vh;

  text-align: center;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.15px;

  /* Color/Dark/400 */

  color: #454b46;
`;

const Explanation = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;
  letter-spacing: 0.15px;


  /* Color/Dark/300 */

  color: #7a7e7a;
`;
