import React from 'react'
import styled from 'styled-components';
import CheckBox from './CheckBox';

interface Props {
  handleCheckBox: (status: boolean) => void
  text: string
  linkText?: string
  link?: string
}

const ConditionsAndTerms = (props: Props): JSX.Element => {
  const { handleCheckBox, text, linkText, link } = props

  return (
    <ConditionsAndTermsContainer>
      <Wrapper>
        <ConditionsAndTermsCheckBox onStatusChange={handleCheckBox} /> 
            <Text>{text}</Text>
            {!!linkText && !!link && ( 
                <UnderlinedLink href={link} target='_blank'>
                  {linkText}
                </UnderlinedLink> 
               )
            }
      </Wrapper>
    </ConditionsAndTermsContainer>
  )
}

export default ConditionsAndTerms

const ConditionsAndTermsContainer = styled.div`
  justify-content: flex-end;
`

const Wrapper = styled.div`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const ConditionsAndTermsCheckBox = styled(CheckBox)`
  padding-right: 8px;
`

const Text = styled.text`
    font-size: 14px;
    padding: 0;
    margin-left: 8px;
    color: #000000;
    textAlign: center;
    textAlignVertical: center;
`;

const UnderlinedLink = styled.a`
  font-size: 14px;
  padding: 0;
  color: #454b46;
  textAlign: center;
  textAlignVertical: center;  
`;