import moment from 'moment'

enum DateFormats {
    YYYY_MM_DD = 'YYYY-MM-DD',
    DD_MM_YYYY = 'DD/MM/YYYY'
}

export default class DateUtils {
    static formatDateToBackend(date?: string) {
        return moment(date, DateFormats.DD_MM_YYYY).format(DateFormats.YYYY_MM_DD)
    }
}