import React, { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import UnicoSDKService from '../services/unico/UnicoSdkService';
import { AppToaster } from './toaster/AppToaster';
import {
  CameraOpener,
  ErrorPictureResponse,
  SuccessPictureResponse,
} from 'unico-webframe';
import FirebaseService from '../services/firebase/FirebaseService';
import BrowserInfoUtils from '../utils/BrowserInfoUtils';
import AppConfig from '../config/config';

interface Props {
  onPhoto: (imageBase64: string) => void;
  sdkCameraInstance: UnicoSDKService;
}

const UnicoSDKCamera: React.FC<Props> = ({
  onPhoto,
  sdkCameraInstance,
}: Props) => {
  const [isHandlingPhoto, setIsHandlingPhoto] = useState<boolean>(true);

  const handleRetryPhoto = () => {
    setIsHandlingPhoto(true);
  };

  const openCamera = async () => {
    // request user permission to open camera, prevent freeze on iOS devices(SAFARI) when not using liveness
    if (
      BrowserInfoUtils.isSafari() &&
      !AppConfig.getConfig().shouldUseLiveness
    ) {
      await navigator.mediaDevices.getUserMedia({ video: true });
    }

    sdkCameraInstance.camera?.then((camOpener: CameraOpener) => {
      camOpener.open({
        on: {
          success: (obj: SuccessPictureResponse) => {
            if (obj.base64.length) {
              onPhoto(obj.base64);
            }
          },
          error: (error: ErrorPictureResponse) => {
            FirebaseService.logAppException(
              `Error taking photo via SDK ${JSON.stringify(error)}`
            );
            setIsHandlingPhoto(false);
            return AppToaster.showErrorToast(
              `Ocorreu um erro ao tirar a foto. ${error.message}`
            );
          },
        },
      });
    });
  };
  if (isHandlingPhoto) openCamera();

  return (
    <>
      <CameraContainer>
        <div
          id='box-camera'
          style={{ justifyContent: 'center', textAlign: 'center' }}
        ></div>
      </CameraContainer>

      {!isHandlingPhoto && (
        <>
          <ErrorExplanation>Erro ao tirar a foto</ErrorExplanation>
          <CustomButton onClick={handleRetryPhoto}>
            Tente novamente
          </CustomButton>
        </>
      )}
    </>
  );
};

export default UnicoSDKCamera;

const CameraContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: 5%;
`;

const ErrorExplanation = styled.span`
  text-align: center;
  color: red;
`;
const CustomButton = styled.button<{ disabled?: boolean }>`
  display: inline-block;
  background-color: #11b048 !important;
  color: white;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 0px 10px 0px;
  margin-top: 10px;
  border: 0px;
`;
