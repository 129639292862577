import BrowserDetector from 'browser-dtector';

export default class BrowserInfoUtils {
  static browserInfos = new BrowserDetector(
    window.navigator.userAgent
  ).parseUserAgent();

  static isMobile(): Boolean {
    return (
      this.browserInfos.isMobile && 'capture' in document.createElement('input')
    );
  }

  static isSafari(): Boolean {
    return this.browserInfos.name?.toString().toLowerCase() == 'safari';
  }
}
