export enum CPFState {
  EMPTY,
  INCOMPLETE,
  INVALID,
  VALID
}

// TODO(lucas.citolin): refactor. CP from credit-spa
export class DocumentUtils {
    private static modulo11(string: string, size: number, mod: number) {
        let soma = 0
        for (let i = 1; i <= size; i++) {
          // tslint:disable-next-line:radix
          soma = soma + parseInt(string.substring(i - 1, i)) * (mod - i)
        }
        let resto = (soma * 10) % 11
      
        if (resto === 10 || resto === 11) {
          resto = 0
        }
        return resto
    }

    private static create_cpf(strCPF: string) {
        strCPF = strCPF.replace(/[^\d]+/g, '')
        if (strCPF === '00000000000') {
          return false
        }
      
        return [this.modulo11(strCPF, 9, 11), this.modulo11(strCPF, 10, 12)]
      }

    static validateCPF(cpf: string): CPFState {
        cpf = cpf.replace(/[^\d]+/g, '')
        if(cpf.length === 0) {
          return CPFState.EMPTY
        }

        if (cpf.length !== 11) {
          return CPFState.INCOMPLETE
        }

        const restos = this.create_cpf(cpf)
      
        if (restos && restos[0] !== parseInt(cpf.substring(9, 10), 10)) {
          return CPFState.INVALID
        }
      
        if (restos && restos[1] !== parseInt(cpf.substring(10, 11), 10)) {
          return CPFState.INVALID
        }
        
        return CPFState.VALID
      
    }
}