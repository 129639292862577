import styled from 'styled-components';
import { ReactComponent as PersonSVGIcon } from '../../assets/icons/person.svg';
import { ReactComponent as AddressSVGIcon } from '../../assets/icons/address.svg';
import { ReactComponent as PersonDocumentSVGIcon } from '../../assets/icons/person_document.svg';
import { ReactComponent as LockSVGIcon } from '../../assets/icons/lock.svg';

const IconsSize = 'large';
const IconsStyle = { color: '#13C450' };

const Steps = [
  { img: <PersonSVGIcon fontSize={IconsSize} style={IconsStyle} />, title: 'Dados pessoais', subtitle: 'Informações básicas sobre você' },
  { img: <AddressSVGIcon fontSize={IconsSize} style={IconsStyle} />, title: 'Dados residenciais', subtitle: 'Necessário seu CEP' },
  {
    img: <PersonDocumentSVGIcon fontSize={IconsSize} style={IconsStyle} />,
    title: 'Foto do documento e uma foto sua',
    subtitle: 'Necessário RG ou CNH em mãos',
  },
  {
    img: <LockSVGIcon fontSize={IconsSize} style={IconsStyle} />,
    title: 'Validação por mensagem de texto',
    subtitle: 'Necesário celular em mãos',
  },
];

const ApplicationFormResume = () => {
  return (
    <Container>
      <Title>
        <StrongTitle>Faça seu cadastro</StrongTitle> e tenha <StrongTitle>crédito</StrongTitle> em poucos passos
      </Title>

      <SubTitle>Sem taxas escondidas ou anuidade.</SubTitle>

      <StepsContainer>
        {Steps.map((step, index) => (
          <>
            {index !== 0 ? (
              <SingleStepContainer>
                <div style={{ width: 37 }}>
                  <VerticalLine />
                </div>
                <VerticalLineBlank> . </VerticalLineBlank>
              </SingleStepContainer>
            ) : undefined}
            <SingleStepContainer key={index}>
              {step.img}
              <StepDescriptionContainer>
                <StepTitle>{step.title}</StepTitle>
                <StepSubTitle>{step.subtitle}</StepSubTitle>
              </StepDescriptionContainer>
            </SingleStepContainer>
          </>
        ))}
      </StepsContainer>
    </Container>
  );
};

export default ApplicationFormResume;

const Container = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;

  // margin-top: 8%;
  margin: 8%;
`;

const Title = styled.span`
  text-align: left;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;

  color: #454b46;
`;

const StrongTitle = styled.span`
  font-weight: 700;
`;

const SubTitle = styled.span`
  margin-top: 2vh;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #7a7e7a;
`;

const StepsContainer = styled.div`
  margin-top: 3vh;
  height: 65%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const SingleStepContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const StepDescriptionContainer = styled.div`
  margin-left: 5vw;
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  text-align: left;
`;

const StepTitle = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #454b46;
`;

const StepSubTitle = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;

  color: #7a7e7a;
`;

const VerticalLineBlank = styled.span`
  flex: 1;
  visibility: hidden;
`;

const VerticalLine = styled.div`
  width: 2px;
  height: 5vh;
  left: 43px;
  top: 320px;

  margin: auto;

  background: #d0fcce;

  text-align: center;
`;
