import createSagaMiddleware from "@redux-saga/core";
import { configureStore } from "@reduxjs/toolkit";
import { all } from "redux-saga/effects";
import applicationReducer from "./reducers/application.reducer";
import applicationSaga from "./saga/application.saga";

const sagaMiddleware = createSagaMiddleware()

export const reduxStore = configureStore({
    reducer: {
      application: applicationReducer.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware)
  })

export function* rootSaga() {
  yield all([
    ...applicationSaga, 
  ])
}

sagaMiddleware.run(rootSaga)

  // Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof reduxStore.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof reduxStore.dispatch
